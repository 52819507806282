import { TableComplaints } from '../../components/comons/Tables/TableComplaints';
import React, { useState, useEffect, useCallback } from 'react';
import { UseComplaints } from '../../hooks/complaints/UseComplaints';
import CircularProgress from '@mui/material/CircularProgress';
import { Modal } from 'antd';
import { FormRespond } from '../../components/complaints/FormRespond';

const DenunciaHeaders = [
	{ label: 'Fecha', field: 'fecha_denuncia' },
	{ label: 'Área Integridad', field: 'area_integridad' },
	{ label: 'Queja', field: 'nombre_denuncia' },
	{ label: 'Anonimo', field: 'anonimato', render: (value) => (value ? 'Sí' : 'No') },
	{ label: 'Nombre', field: 'nombre_completo' },
	{ label: 'Cargo', field: 'cargo' },
	{ label: 'Celular', field: 'telefono_denuncia' },
	{ label: 'Correo', field: 'correo_denuncia' },
	{ label: 'Fecha Hechos', field: 'fecha_hechos' },
	{ label: 'Lugar Hechos', field: 'lugar_hechos' },
	{ label: 'Anexos', field: 'anexos' },
];

const popoverColumns = [
	{ label: 'ID Queja', field: 'id_denuncia' },
	{ label: 'Descripción de los Hechos', field: 'descripcion_hechos' },
];

export function PageComplaints() {
	const [refetch, setRefetch] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [contentModal, setContentModal] = useState(null);

	const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
	const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
	const { loading: complaintsLoading, denuncias, getDenuncia } = UseComplaints();

	const handleStatusForm = useCallback(
		(denuncia) => {
			setContentModal({
				onClose: setContentModal,
				onRefetch: onRefetch,
				denuncias: denuncia
			});
			openCloseModal();
		},
		[openCloseModal, onRefetch]
	);

	const handleResponder = useCallback(
		(denuncia) => {
			setContentModal(
				<FormRespond
					denuncias={denuncia}
					onClose={openCloseModal}
					onRefetch={onRefetch}
				/>
			);
			openCloseModal();
		},
		[openCloseModal, onRefetch]
	);


	// Obtener las denuncias cuando se hace el refetch
	useEffect(() => {
		getDenuncia();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refetch]);

	return (
		<>
			{complaintsLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh'
					}}>
					<CircularProgress />
				</div>
			) : (
				<TableComplaints
					Data={denuncias}
					columns={DenunciaHeaders}
					popoverColumns={popoverColumns}
					handleStatus={handleStatusForm}
					handleResponder={handleResponder}
				/>
			)}

			<Modal
				style={{ top: 20 }}
				open={showModal}
				onCancel={openCloseModal}
				footer={null}
				width="70%"
				destroyOnClose={true}
			>
				{contentModal && React.isValidElement(contentModal) ? contentModal : null}
			</Modal>
		</>
	);
}
