import React, { useEffect } from "react";
import * as Yup from "yup";
import { Button, Col, Form, Input, Row, message, Select, Spin, Table } from "antd";
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { FormStyle } from "../../../comons/styles";
import { useControlRiesgos } from "../../../../hooks/administrativa/formularios-sig/useControlRiesgos";
import { initialValuesControlRiesgos } from "../components/initialValues";

export function ControlRiesgosForm(props) {

  const { updateControlRiesgos, cancelLoader, loading } = useControlRiesgos();
  const { dataTipoFormulario, data, handleNext, handlePrev, id, setnewdataControlRiesgos } = props;
  const { Option } = Select;

  useEffect(() => {
    cancelLoader();
  }, [data])
  
  const formik = useFormik({
    initialValues: initialValuesControlRiesgos(id.Id_formulario, data),
    //validationSchema: analisisTrabajoSchema,

    onSubmit: async (formValues) => {
      try {
        await updateControlRiesgos(id.Id_controlriesgos, formValues);
        message.success("Actualización exitosa (Control de riesgos)");
        setnewdataControlRiesgos(formValues)
        handleNext();
      } catch (error) {
        message.error("Error en la operación (Control de riesgos)");
      }
    },
  });
  
  return (
    <>
    {loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </div>

    ) : (

    <Form layout="vertical" onFinish={formik.handleSubmit} style={FormStyle}>
      { dataTipoFormulario !== 'PT Confinado' &&  (
        <>
      <Row gutter={16}>
        <Col style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}} >
          EPP
        </Col>
      </Row>      
      <hr/>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Protección Cabeza"
              validateStatus={formik.errors.proteccioncabeza && formik.touched.proteccioncabeza ? "error" : ""}
              help={formik.touched.proteccioncabeza && formik.errors.proteccioncabeza ? formik.errors.proteccioncabeza : ""}
          >
            <Select
                name="proteccioncabeza"
                value={formik.values.proteccioncabeza}
                onChange={(value) => formik.setFieldValue('proteccioncabeza', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Protección facial"
              validateStatus={formik.errors.proteccionfacial && formik.touched.proteccionfacial ? "error" : ""}
              help={formik.touched.proteccionfacial && formik.errors.proteccionfacial ? formik.errors.proteccionfacial : ""}
          >
            <Select
                name="proteccionfacial"
                value={formik.values.proteccionfacial}
                onChange={(value) => formik.setFieldValue('proteccionfacial', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Protección visual"
              validateStatus={formik.errors.proteccionvisual && formik.touched.proteccionvisual ? "error" : ""}
              help={formik.touched.proteccionvisual && formik.errors.proteccionvisual ? formik.errors.proteccionvisual : ""}
          >
            <Select
                name="proteccionvisual"
                value={formik.values.proteccionvisual}
                onChange={(value) => formik.setFieldValue('proteccionvisual', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Protección respiratoria"
              validateStatus={formik.errors.proteccionrespiratoria && formik.touched.proteccionrespiratoria ? "error" : ""}
              help={formik.touched.proteccionrespiratoria && formik.errors.proteccionrespiratoria ? formik.errors.proteccionrespiratoria : ""}
          >
            <Select
                name="proteccionrespiratoria"
                value={formik.values.proteccionrespiratoria}
                onChange={(value) => formik.setFieldValue('proteccionrespiratoria', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Protección auditiva"
              validateStatus={formik.errors.proteccionauditiva && formik.touched.proteccionauditiva ? "error" : ""}
              help={formik.touched.proteccionauditiva && formik.errors.proteccionauditiva ? formik.errors.proteccionauditiva : ""}
          >
            <Select
                name="proteccionauditiva"
                value={formik.values.proteccionauditiva}
                onChange={(value) => formik.setFieldValue('proteccionauditiva', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Protección manual"
              validateStatus={formik.errors.proteccionmanual && formik.touched.proteccionmanual ? "error" : ""}
              help={formik.touched.proteccionmanual && formik.errors.proteccionmanual ? formik.errors.proteccionmanual : ""}
          >
            <Select
                name="proteccionmanual"
                value={formik.values.proteccionmanual}
                onChange={(value) => formik.setFieldValue('proteccionmanual', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Protección pies"
              validateStatus={formik.errors.proteccionpies && formik.touched.proteccionpies ? "error" : ""}
              help={formik.touched.proteccionpies && formik.errors.proteccionpies ? formik.errors.proteccionpies : ""}
          >
            <Select
                name="proteccionpies"
                value={formik.values.proteccionpies}
                onChange={(value) => formik.setFieldValue('proteccionpies', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Ropa de protección"
              validateStatus={formik.errors.ropaproteccion && formik.touched.ropaproteccion ? "error" : ""}
              help={formik.touched.ropaproteccion && formik.errors.ropaproteccion ? formik.errors.ropaproteccion : ""}
          >
            <Select
                name="ropaproteccion"
                value={formik.values.ropaproteccion}
                onChange={(value) => formik.setFieldValue('ropaproteccion', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Accesorios adicionales (Cinturon, etc)"
              validateStatus={formik.errors.accesorioadicional && formik.touched.accesorioadicional ? "error" : ""}
              help={formik.touched.accesorioadicional && formik.errors.accesorioadicional ? formik.errors.accesorioadicional : ""}
          >
            <Select
                name="accesorioadicional"
                value={formik.values.accesorioadicional}
                onChange={(value) => formik.setFieldValue('accesorioadicional', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        { dataTipoFormulario !== 'ATS' &&  (
        <Col span={8}>
          <Form.Item
              label="Sistema de bloqueo y etiquetado"
              validateStatus={formik.errors.sistemabloeti && formik.touched.sistemabloeti ? "error" : ""}
              help={formik.touched.sistemabloeti && formik.errors.sistemabloeti ? formik.errors.sistemabloeti : ""}
          >
            <Select
                name="sistemabloeti"
                value={formik.values.sistemabloeti}
                onChange={(value) => formik.setFieldValue('sistemabloeti', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        )}
        <Col span={8}>
            <Form.Item
                label="Otra protección especial"
                validateStatus={formik.errors.otraproteccion && formik.touched.otraproteccion ? "error" : ""}
                help={formik.touched.otraproteccion && formik.errors.otraproteccion
                ? formik.errors.otraproteccion
                : ""}
            >
                <Input
                    name="otraproteccion"
                    value={formik.values.otraproteccion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                />
            </Form.Item>
        </Col>
      </Row>

      { dataTipoFormulario !== 'ATS' &&  (
      <>
      <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>EPP contra caidas</Col></Row>
      <hr/>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Eslinga con absorvedor de impacto"
              validateStatus={formik.errors.esligaimpacto && formik.touched.esligaimpacto ? "error" : ""}
              help={formik.touched.esligaimpacto && formik.errors.esligaimpacto ? formik.errors.esligaimpacto : ""}
          >
            <Select
                name="esligaimpacto"
                value={formik.values.esligaimpacto}
                onChange={(value) => formik.setFieldValue('esligaimpacto', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Eslinga de posicionamiento"
              validateStatus={formik.errors.eslingaposicionamiento && formik.touched.eslingaposicionamiento ? "error" : ""}
              help={formik.touched.eslingaposicionamiento && formik.errors.eslingaposicionamiento ? formik.errors.eslingaposicionamiento : ""}
          >
            <Select
                name="eslingaposicionamiento"
                value={formik.values.eslingaposicionamiento}
                onChange={(value) => formik.setFieldValue('eslingaposicionamiento', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Eslinga en Y con amortiguador de impacto"
              validateStatus={formik.errors.eslingay && formik.touched.eslingay ? "error" : ""}
              help={formik.touched.eslingay && formik.errors.eslingay ? formik.errors.eslingay : ""}
          >
            <Select
                name="eslingay"
                value={formik.values.eslingay}
                onChange={(value) => formik.setFieldValue('eslingay', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Eslinga en Y de 60 cm sin amortiguador"
              validateStatus={formik.errors.esliga60 && formik.touched.esliga60 ? "error" : ""}
              help={formik.touched.esliga60 && formik.errors.esliga60 ? formik.errors.esliga60 : ""}
          >
            <Select
                name="esliga60"
                value={formik.values.esliga60}
                onChange={(value) => formik.setFieldValue('esliga60', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Tie off / anclaje portátil"
              validateStatus={formik.errors.tieoff && formik.touched.tieoff ? "error" : ""}
              help={formik.touched.tieoff && formik.errors.tieoff ? formik.errors.tieoff : ""}
          >
            <Select
                name="tieoff"
                value={formik.values.tieoff}
                onChange={(value) => formik.setFieldValue('tieoff', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Sistema de línea de vida vertical portátil y mosquetón"
              validateStatus={formik.errors.lvvertical && formik.touched.lvvertical ? "error" : ""}
              help={formik.touched.lvvertical && formik.errors.lvvertical ? formik.errors.lvvertical : ""}
          >
            <Select
                name="lvvertical"
                value={formik.values.lvvertical}
                onChange={(value) => formik.setFieldValue('lvvertical', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Sistema de línea de vida horizontal portátil"
              validateStatus={formik.errors.lvhorizontal && formik.touched.lvhorizontal ? "error" : ""}
              help={formik.touched.lvhorizontal && formik.errors.lvhorizontal ? formik.errors.lvhorizontal : ""}
          >
            <Select
                name="lvhorizontal"
                value={formik.values.lvhorizontal}
                onChange={(value) => formik.setFieldValue('lvhorizontal', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Punto de anclaje fijo"
              validateStatus={formik.errors.puntoanclaje && formik.touched.puntoanclaje ? "error" : ""}
              help={formik.touched.puntoanclaje && formik.errors.puntoanclaje ? formik.errors.puntoanclaje : ""}
          >
            <Select
                name="puntoanclaje"
                value={formik.values.puntoanclaje}
                onChange={(value) => formik.setFieldValue('puntoanclaje', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      </>
      )}

      { dataTipoFormulario !== 'PT Alturas' &&  (
       <>
      <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Otros controles</Col></Row>
      <hr/>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Procedimiento de trabajo seguro"
              validateStatus={formik.errors.proctrabajo && formik.touched.proctrabajo ? "error" : ""}
              help={formik.touched.proctrabajo && formik.errors.proctrabajo ? formik.errors.proctrabajo : ""}
          >
            <Select
                name="proctrabajo"
                value={formik.values.proctrabajo}
                onChange={(value) => formik.setFieldValue('proctrabajo', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Duchas y lavaojos"
              validateStatus={formik.errors.duchas && formik.touched.duchas ? "error" : ""}
              help={formik.touched.duchas && formik.errors.duchas ? formik.errors.duchas : ""}
          >
            <Select
                name="duchas"
                value={formik.values.duchas}
                onChange={(value) => formik.setFieldValue('duchas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Sistema de acceso trabajo en alturas"
              validateStatus={formik.errors.trabajoalturas && formik.touched.trabajoalturas ? "error" : ""}
              help={formik.touched.trabajoalturas && formik.errors.trabajoalturas ? formik.errors.trabajoalturas : ""}
          >
            <Select
                name="trabajoalturas"
                value={formik.values.trabajoalturas}
                onChange={(value) => formik.setFieldValue('trabajoalturas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Bloqueo y etiquetado"
              validateStatus={formik.errors.bloqueoetiquetado && formik.touched.bloqueoetiquetado ? "error" : ""}
              help={formik.touched.bloqueoetiquetado && formik.errors.bloqueoetiquetado ? formik.errors.bloqueoetiquetado : ""}
          >
            <Select
                name="bloqueoetiquetado"
                value={formik.values.bloqueoetiquetado}
                onChange={(value) => formik.setFieldValue('bloqueoetiquetado', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Permiso de trabajo en frio"
              validateStatus={formik.errors.trabajofrio && formik.touched.trabajofrio ? "error" : ""}
              help={formik.touched.trabajofrio && formik.errors.trabajofrio ? formik.errors.trabajofrio : ""}
          >
            <Select
                name="trabajofrio"
                value={formik.values.trabajofrio}
                onChange={(value) => formik.setFieldValue('trabajofrio', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Equipos de control de incendios"
              validateStatus={formik.errors.controlincedios && formik.touched.controlincedios ? "error" : ""}
              help={formik.touched.controlincedios && formik.errors.controlincedios ? formik.errors.controlincedios : ""}
          >
            <Select
                name="controlincedios"
                value={formik.values.controlincedios}
                onChange={(value) => formik.setFieldValue('controlincedios', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Señalización y demarcación"
              validateStatus={formik.errors.senalizacion && formik.touched.senalizacion ? "error" : ""}
              help={formik.touched.senalizacion && formik.errors.senalizacion ? formik.errors.senalizacion : ""}
          >
            <Select
                name="senalizacion"
                value={formik.values.senalizacion}
                onChange={(value) => formik.setFieldValue('senalizacion', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Procedimiento de evaluación / MEDEVAC"
              validateStatus={formik.errors.medevac && formik.touched.medevac ? "error" : ""}
              help={formik.touched.medevac && formik.errors.medevac ? formik.errors.medevac : ""}
          >
            <Select
                name="medevac"
                value={formik.values.medevac}
                onChange={(value) => formik.setFieldValue('medevac', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Sistema de protección contra caidas"
              validateStatus={formik.errors.proteccioncaidas && formik.touched.proteccioncaidas ? "error" : ""}
              help={formik.touched.proteccioncaidas && formik.errors.proteccioncaidas ? formik.errors.proteccioncaidas : ""}
          >
            <Select
                name="proteccioncaidas"
                value={formik.values.proteccioncaidas}
                onChange={(value) => formik.setFieldValue('proteccioncaidas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Monitoreo continuo de explosividad"
              validateStatus={formik.errors.explosividad && formik.touched.explosividad ? "error" : ""}
              help={formik.touched.explosividad && formik.errors.explosividad ? formik.errors.explosividad : ""}
          >
            <Select
                name="explosividad"
                value={formik.values.explosividad}
                onChange={(value) => formik.setFieldValue('explosividad', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Permiso de trabajo en caliente"
              validateStatus={formik.errors.trabajocaliente && formik.touched.trabajocaliente ? "error" : ""}
              help={formik.touched.trabajocaliente && formik.errors.trabajocaliente ? formik.errors.trabajocaliente : ""}
          >
            <Select
                name="trabajocaliente"
                value={formik.values.trabajocaliente}
                onChange={(value) => formik.setFieldValue('trabajocaliente', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Procedimiento de control de derrames"
              validateStatus={formik.errors.controlderrames && formik.touched.controlderrames ? "error" : ""}
              help={formik.touched.controlderrames && formik.errors.controlderrames ? formik.errors.controlderrames : ""}
          >
            <Select
                name="controlderrames"
                value={formik.values.controlderrames}
                onChange={(value) => formik.setFieldValue('controlderrames', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Hojas de seguridad"
              validateStatus={formik.errors.hojaseguridad && formik.touched.hojaseguridad ? "error" : ""}
              help={formik.touched.hojaseguridad && formik.errors.hojaseguridad ? formik.errors.hojaseguridad : ""}
          >
            <Select
                name="hojaseguridad"
                value={formik.values.hojaseguridad}
                onChange={(value) => formik.setFieldValue('hojaseguridad', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Permiso de trabajo en alturas"
              validateStatus={formik.errors.trabajoaltura && formik.touched.trabajoaltura ? "error" : ""}
              help={formik.touched.trabajoaltura && formik.errors.trabajoaltura ? formik.errors.trabajoaltura : ""}
          >
            <Select
                name="trabajoaltura"
                value={formik.values.trabajoaltura}
                onChange={(value) => formik.setFieldValue('trabajoaltura', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Equipo de primeros auxilios"
              validateStatus={formik.errors.primerosauxilios && formik.touched.primerosauxilios ? "error" : ""}
              help={formik.touched.primerosauxilios && formik.errors.primerosauxilios ? formik.errors.primerosauxilios : ""}
          >
            <Select
                name="primerosauxilios"
                value={formik.values.primerosauxilios}
                onChange={(value) => formik.setFieldValue('primerosauxilios', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Barrera física"
              validateStatus={formik.errors.barrerafisica && formik.touched.barrerafisica ? "error" : ""}
              help={formik.touched.barrerafisica && formik.errors.barrerafisica ? formik.errors.barrerafisica : ""}
          >
            <Select
                name="barrerafisica"
                value={formik.values.barrerafisica}
                onChange={(value) => formik.setFieldValue('barrerafisica', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Permiso de trabajo espacios confinados"
              validateStatus={formik.errors.espacioconfinado && formik.touched.espacioconfinado ? "error" : ""}
              help={formik.touched.espacioconfinado && formik.errors.espacioconfinado ? formik.errors.espacioconfinado : ""}
          >
            <Select
                name="espacioconfinado"
                value={formik.values.espacioconfinado}
                onChange={(value) => formik.setFieldValue('espacioconfinado', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Supervisor / Vigia HSE con licencia"
              validateStatus={formik.errors.vigiahse && formik.touched.vigiahse ? "error" : ""}
              help={formik.touched.vigiahse && formik.errors.vigiahse ? formik.errors.vigiahse : ""}
          >
            <Select
                name="vigiahse"
                value={formik.values.vigiahse}
                onChange={(value) => formik.setFieldValue('vigiahse', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="By-pass, desgasificación"
              validateStatus={formik.errors.bypass && formik.touched.bypass ? "error" : ""}
              help={formik.touched.bypass && formik.errors.bypass ? formik.errors.bypass : ""}
          >
            <Select
                name="bypass"
                value={formik.values.bypass}
                onChange={(value) => formik.setFieldValue('bypass', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Permiso de trabajo eléctrico"
              validateStatus={formik.errors.trabajoelectrico && formik.touched.trabajoelectrico ? "error" : ""}
              help={formik.touched.trabajoelectrico && formik.errors.trabajoelectrico ? formik.errors.trabajoelectrico : ""}
          >
            <Select
                name="trabajoelectrico"
                value={formik.values.trabajoelectrico}
                onChange={(value) => formik.setFieldValue('trabajoelectrico', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Otros (controles)"
              validateStatus={formik.errors.otroscontroles && formik.touched.otroscontroles ? "error" : ""}
              help={formik.touched.otroscontroles && formik.errors.otroscontroles ? formik.errors.otroscontroles : ""}
          >
            <Input
                name="otroscontroles"
                value={formik.values.otroscontroles}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} 
            />
          </Form.Item>
        </Col>
      </Row>
      </>
      )}
      </>
      )}
      { dataTipoFormulario !== 'PT Alturas' &&  (
       <>
      <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Equipo de seguridad requerido</Col></Row>
      <hr/>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Casco de seguridad no dielectrico"
              validateStatus={formik.errors.casconodielectrico && formik.touched.casconodielectrico ? "error" : ""}
              help={formik.touched.casconodielectrico && formik.errors.casconodielectrico ? formik.errors.casconodielectrico : ""}
          >
            <Select
                name="casconodielectrico"
                value={formik.values.casconodielectrico}
                onChange={(value) => formik.setFieldValue('casconodielectrico', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Casco de seguridad dielectrico"
              validateStatus={formik.errors.cascodielectrico && formik.touched.cascodielectrico ? "error" : ""}
              help={formik.touched.cascodielectrico && formik.errors.cascodielectrico ? formik.errors.cascodielectrico : ""}
          >
            <Select
                name="cascodielectrico"
                value={formik.values.cascodielectrico}
                onChange={(value) => formik.setFieldValue('cascodielectrico', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Botas de seguridad no dielectricas"
              validateStatus={formik.errors.botasnodielectricas && formik.touched.botasnodielectricas ? "error" : ""}
              help={formik.touched.botasnodielectricas && formik.errors.botasnodielectricas ? formik.errors.botasnodielectricas : ""}
          >
            <Select
                name="botasnodielectricas"
                value={formik.values.botasnodielectricas}
                onChange={(value) => formik.setFieldValue('botasnodielectricas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Botas de seguridad dielectricas"
              validateStatus={formik.errors.botasdielectricas && formik.touched.botasdielectricas ? "error" : ""}
              help={formik.touched.botasdielectricas && formik.errors.botasdielectricas ? formik.errors.botasdielectricas : ""}
          >
            <Select
                name="botasdielectricas"
                value={formik.values.botasdielectricas}
                onChange={(value) => formik.setFieldValue('botasdielectricas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Gafas de seguridad"
              validateStatus={formik.errors.gafaseguridad && formik.touched.gafaseguridad ? "error" : ""}
              help={formik.touched.gafaseguridad && formik.errors.gafaseguridad ? formik.errors.gafaseguridad : ""}
          >
            <Select
                name="gafaseguridad"
                value={formik.values.gafaseguridad}
                onChange={(value) => formik.setFieldValue('gafaseguridad', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Careta de soldador"
              validateStatus={formik.errors.caretasoldador && formik.touched.caretasoldador ? "error" : ""}
              help={formik.touched.caretasoldador && formik.errors.caretasoldador ? formik.errors.caretasoldador : ""}
          >
            <Select
                name="caretasoldador"
                value={formik.values.caretasoldador}
                onChange={(value) => formik.setFieldValue('caretasoldador', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Traje impermeable"
              validateStatus={formik.errors.trajeimpermeable && formik.touched.trajeimpermeable ? "error" : ""}
              help={formik.touched.trajeimpermeable && formik.errors.trajeimpermeable ? formik.errors.trajeimpermeable : ""}
          >
            <Select
                name="trajeimpermeable"
                value={formik.values.trajeimpermeable}
                onChange={(value) => formik.setFieldValue('trajeimpermeable', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Chaleco reflectivo / Salvavidas"
              validateStatus={formik.errors.chalecoreflectivo && formik.touched.chalecoreflectivo ? "error" : ""}
              help={formik.touched.chalecoreflectivo && formik.errors.chalecoreflectivo ? formik.errors.chalecoreflectivo : ""}
          >
            <Select
                name="chalecoreflectivo"
                value={formik.values.chalecoreflectivo}
                onChange={(value) => formik.setFieldValue('chalecoreflectivo', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Peto, polaina y mangas para soldar"
              validateStatus={formik.errors.petomangas && formik.touched.petomangas ? "error" : ""}
              help={formik.touched.petomangas && formik.errors.petomangas ? formik.errors.petomangas : ""}
          >
            <Select
                name="petomangas"
                value={formik.values.petomangas}
                onChange={(value) => formik.setFieldValue('petomangas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Protección auditiva de inserción"
              validateStatus={formik.errors.protauditivainsercion && formik.touched.protauditivainsercion ? "error" : ""}
              help={formik.touched.protauditivainsercion && formik.errors.protauditivainsercion ? formik.errors.protauditivainsercion : ""}
          >
            <Select
                name="protauditivainsercion"
                value={formik.values.protauditivainsercion}
                onChange={(value) => formik.setFieldValue('protauditivainsercion', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Protección auditiva tipo copa"
              validateStatus={formik.errors.protauditivacopa && formik.touched.protauditivacopa ? "error" : ""}
              help={formik.touched.protauditivacopa && formik.errors.protauditivacopa ? formik.errors.protauditivacopa : ""}
          >
            <Select
                name="protauditivacopa"
                value={formik.values.protauditivacopa}
                onChange={(value) => formik.setFieldValue('protauditivacopa', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Overol"
              validateStatus={formik.errors.overol && formik.touched.overol ? "error" : ""}
              help={formik.touched.overol && formik.errors.overol ? formik.errors.overol : ""}
          >
            <Select
                name="overol"
                value={formik.values.overol}
                onChange={(value) => formik.setFieldValue('overol', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Guantes de cuero (Vaqueta)"
              validateStatus={formik.errors.guantescuero && formik.touched.guantescuero ? "error" : ""}
              help={formik.touched.guantescuero && formik.errors.guantescuero ? formik.errors.guantescuero : ""}
          >
            <Select
                name="guantescuero"
                value={formik.values.guantescuero}
                onChange={(value) => formik.setFieldValue('guantescuero', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Guantes de hilaza con puntos"
              validateStatus={formik.errors.guanteshilaza && formik.touched.guanteshilaza ? "error" : ""}
              help={formik.touched.guanteshilaza && formik.errors.guanteshilaza ? formik.errors.guanteshilaza : ""}
          >
            <Select
                name="guanteshilaza"
                value={formik.values.guanteshilaza}
                onChange={(value) => formik.setFieldValue('guanteshilaza', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Guantes de nitrilo"
              validateStatus={formik.errors.guantesnitrilo && formik.touched.guantesnitrilo ? "error" : ""}
              help={formik.touched.guantesnitrilo && formik.errors.guantesnitrilo ? formik.errors.guantesnitrilo : ""}
          >
            <Select
                name="guantesnitrilo"
                value={formik.values.guantesnitrilo}
                onChange={(value) => formik.setFieldValue('guantesnitrilo', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Guantes de latex"
              validateStatus={formik.errors.guanteslatex && formik.touched.guanteslatex ? "error" : ""}
              help={formik.touched.guanteslatex && formik.errors.guanteslatex ? formik.errors.guanteslatex : ""}
          >
            <Select
                name="guanteslatex"
                value={formik.values.guanteslatex}
                onChange={(value) => formik.setFieldValue('guanteslatex', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Guantes de poliuretano"
              validateStatus={formik.errors.guantespoliuretano && formik.touched.guantespoliuretano ? "error" : ""}
              help={formik.touched.guantespoliuretano && formik.errors.guantespoliuretano ? formik.errors.guantespoliuretano : ""}
          >
            <Select
                name="guantespoliuretano"
                value={formik.values.guantespoliuretano}
                onChange={(value) => formik.setFieldValue('guantespoliuretano', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Tapabocas"
              validateStatus={formik.errors.tapabocas && formik.touched.tapabocas ? "error" : ""}
              help={formik.touched.tapabocas && formik.errors.tapabocas ? formik.errors.tapabocas : ""}
          >
            <Select
                name="tapabocas"
                value={formik.values.tapabocas}
                onChange={(value) => formik.setFieldValue('tapabocas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Detector LEL / Oxigeno"
              validateStatus={formik.errors.detectorlel && formik.touched.detectorlel ? "error" : ""}
              help={formik.touched.detectorlel && formik.errors.detectorlel ? formik.errors.detectorlel : ""}
          >
            <Select
                name="detectorlel"
                value={formik.values.detectorlel}
                onChange={(value) => formik.setFieldValue('detectorlel', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Linea de vida"
              validateStatus={formik.errors.lineavida && formik.touched.lineavida ? "error" : ""}
              help={formik.touched.lineavida && formik.errors.lineavida ? formik.errors.lineavida : ""}
          >
            <Select
                name="lineavida"
                value={formik.values.lineavida}
                onChange={(value) => formik.setFieldValue('lineavida', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Eslingas"
              validateStatus={formik.errors.eslingas && formik.touched.eslingas ? "error" : ""}
              help={formik.touched.eslingas && formik.errors.eslingas ? formik.errors.eslingas : ""}
          >
            <Select
                name="eslingas"
                value={formik.values.eslingas}
                onChange={(value) => formik.setFieldValue('eslingas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
 
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Arnés cuerpo completo"
              validateStatus={formik.errors.arnescuerpo && formik.touched.arnescuerpo ? "error" : ""}
              help={formik.touched.arnescuerpo && formik.errors.arnescuerpo ? formik.errors.arnescuerpo : ""}
          >
            <Select
                name="arnescuerpo"
                value={formik.values.arnescuerpo}
                onChange={(value) => formik.setFieldValue('arnescuerpo', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Respirador gases y vapores"
              validateStatus={formik.errors.respiradorgases && formik.touched.respiradorgases ? "error" : ""}
              help={formik.touched.respiradorgases && formik.errors.respiradorgases ? formik.errors.respiradorgases : ""}
          >
            <Select
                name="respiradorgases"
                value={formik.values.respiradorgases}
                onChange={(value) => formik.setFieldValue('respiradorgases', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Respirador humos metalicos"
              validateStatus={formik.errors.respiradorhumos && formik.touched.respiradorhumos ? "error" : ""}
              help={formik.touched.respiradorhumos && formik.errors.respiradorhumos ? formik.errors.respiradorhumos : ""}
          >
            <Select
                name="respiradorhumos"
                value={formik.values.respiradorhumos}
                onChange={(value) => formik.setFieldValue('respiradorhumos', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Respirador polvos / Nieblas"
              validateStatus={formik.errors.respiradorpolvos && formik.touched.respiradorpolvos ? "error" : ""}
              help={formik.touched.respiradorpolvos && formik.errors.respiradorpolvos ? formik.errors.respiradorpolvos : ""}
          >
            <Select
                name="respiradorpolvos"
                value={formik.values.respiradorpolvos}
                onChange={(value) => formik.setFieldValue('respiradorpolvos', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Respirador libre mantenimiento con valvula"
              validateStatus={formik.errors.respiradorlibre && formik.touched.respiradorlibre ? "error" : ""}
              help={formik.touched.respiradorlibre && formik.errors.respiradorlibre ? formik.errors.respiradorlibre : ""}
          >
            <Select
                name="respiradorlibre"
                value={formik.values.respiradorlibre}
                onChange={(value) => formik.setFieldValue('respiradorlibre', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Otros (Equipos de seguridad)"
              validateStatus={formik.errors.otrosseguridad && formik.touched.otrosseguridad ? "error" : ""}
              help={formik.touched.otrosseguridad && formik.errors.otrosseguridad ? formik.errors.otrosseguridad : ""}
          >
            <Input
                name="otrosseguridad"
                value={formik.values.otrosseguridad}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} 
            />
          </Form.Item>
        </Col>
      </Row>
      </>
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="primary" htmlType="button" onClick={handlePrev}>
            Anterior
          </Button>
          <Button type="primary" htmlType="submit">
            Actualizar
          </Button>
          <Button type="primary" style={{ background: "black", marginLeft: "5px" }} onClick={handleNext}>
            Siguiente
          </Button>
      </div>

    </Form>
    )}
    </>
  );

}


export function ControlRiesgosFullView(props) {
  const { cancelLoader, loading } = useControlRiesgos();
  const { dataTipoFormulario, data } = props;

  useEffect(() => {
    cancelLoader();
  }, [data]);

  const formik = useFormik({
    initialValues: initialValuesControlRiesgos(data.Id_formulario, data),
  });

  const columns = [
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
      width: '30%',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      width: '70%',
    },
  ];

  const dataSource = [
    // EPP
    { category: 'Protección Cabeza', description: formik.values.proteccioncabeza },
    { category: 'Protección Facial', description: formik.values.proteccionfacial },
    { category: 'Protección Visual', description: formik.values.proteccionvisual },
    { category: 'Protección Respiratoria', description: formik.values.proteccionrespiratoria },
    { category: 'Protección Auditiva', description: formik.values.proteccionauditiva },
    { category: 'Protección Manual', description: formik.values.proteccionmanual },
    { category: 'Protección Pies', description: formik.values.proteccionpies },
    { category: 'Ropa de Protección', description: formik.values.ropaproteccion },
    { category: 'Sistema de Bloqueo y Etiquetado', description: formik.values.sistemabloeti, hidden: dataTipoFormulario === 'ATS' },
    { category: 'Accesorios Adicionales (Cinturón, etc)', description: formik.values.accesorioadicional },
    { category: 'Otra Protección Especial', description: formik.values.otraproteccion },

    // EPP contra caídas
    { category: 'Eslinga con Absorbedor de Impacto', description: formik.values.esligaimpacto, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Eslinga de Posicionamiento', description: formik.values.eslingaposicionamiento, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Eslinga en Y con Amortiguador de Impacto', description: formik.values.eslingay, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Eslinga en Y de 60 cm sin Amortiguador', description: formik.values.esliga60, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Tie Off / Anclaje Portátil', description: formik.values.tieoff, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Sistema de Línea de Vida Vertical Portátil y Mosquetón', description: formik.values.lvvertical, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Sistema de Línea de Vida Horizontal Portátil', description: formik.values.lvhorizontal, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Punto de Anclaje Fijo', description: formik.values.puntoanclaje, hidden: dataTipoFormulario === 'PT Alturas' },

    // Otros controles
    { category: 'Procedimiento de Trabajo Seguro', description: formik.values.proctrabajo, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Duchas y Lavaojos', description: formik.values.duchas, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Sistema de Acceso Trabajo en Alturas', description: formik.values.trabajoalturas, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Bloqueo y Etiquetado', description: formik.values.bloqueoetiquetado, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Permiso de Trabajo en Frío', description: formik.values.trabajofrio, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Equipos de Control de Incendios', description: formik.values.controlincedios, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Señalización y Demarcación', description: formik.values.senalizacion, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Procedimiento de Evaluación / MEDEVAC', description: formik.values.medevac, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Sistema de Protección Contra Caídas', description: formik.values.proteccioncaidas, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Monitoreo Continuo de Explosividad', description: formik.values.explosividad, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Permiso de Trabajo en Caliente', description: formik.values.trabajocaliente, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Procedimiento de Control de Derrames', description: formik.values.controlderrames, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Hojas de Seguridad', description: formik.values.hojaseguridad, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Permiso de Trabajo en Alturas', description: formik.values.trabajoaltura, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Equipo de Primeros Auxilios', description: formik.values.primerosauxilios, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Barrera Física', description: formik.values.barrerafisica, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Permiso de Trabajo Espacios Confinados', description: formik.values.espacioconfinado, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Supervisor / Vigía HSE con Licencia', description: formik.values.vigiahse, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'By-pass, Desgasificación', description: formik.values.bypass, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Permiso de Trabajo Eléctrico', description: formik.values.trabajoelectrico, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Otros (Controles)', description: formik.values.otroscontroles, hidden: dataTipoFormulario === 'PT Alturas' },

    // Equipo de seguridad requerido
    { category: 'Casco de Seguridad No Dielectrico', description: formik.values.casconodielectrico, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Casco de Seguridad Dielectrico', description: formik.values.cascodielectrico, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Botas de Seguridad No Dielectricas', description: formik.values.botasnodielectricas, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Botas de Seguridad Dielectricas', description: formik.values.botasdielectricas, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Gafas de Seguridad', description: formik.values.gafaseguridad, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Careta de Soldador', description: formik.values.caretasoldador, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Traje Impermeable', description: formik.values.trajeimpermeable, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Chaleco Reflectivo / Salvavidas', description: formik.values.chalecoreflectivo, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Peto, Polaina y Mangas para Soldar', description: formik.values.petomangas, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Protección Auditiva de Inserción', description: formik.values.protauditivainsercion, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Protección Auditiva Tipo Copa', description: formik.values.protauditivacopa, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Overol', description: formik.values.overol, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Guantes de Cuero (Vaqueta)', description: formik.values.guantescuero, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Guantes de Hilaza con Puntos', description: formik.values.guanteshilaza, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Guantes de Nitrilo', description: formik.values.guantesnitrilo, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Guantes de Latex', description: formik.values.guanteslatex, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Guantes de Poliuretano', description: formik.values.guantespoliuretano, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Tapabocas', description: formik.values.tapabocas, hidden: dataTipoFormulario === 'PT Alturas' },
    { category: 'Detector LEL / Oxígeno', description: formik.values.detectorlel, hidden: dataTipoFormulario === 'PT Alturas' },
  ];

  const filteredDataSource = dataSource.filter(item => !item.hidden);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: '20px', overflowX: 'auto' }}>
      <Row style={{ fontSize: '25px', fontWeight: 'bold', color: 'rgb(204, 48, 43)' }}>
            Control de riesgos
          </Row>
      <Table
        dataSource={filteredDataSource}
        columns={columns}
        pagination={false}
        rowKey="category"
        bordered
      />
    </div>
  );
}

export default ControlRiesgosFullView;
// export function ControlRiesgosFullView(props) {

//   const { cancelLoader, loading } = useControlRiesgos();
//   const { dataTipoFormulario, data } = props;

//   useEffect(() => {
//     cancelLoader();
//   }, [data])
  
//   const formik = useFormik({
//     initialValues: initialValuesControlRiesgos(data.Id_formulario, data),
//   });
  
//   return (
//     <>
//     {loading ? (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress />
//       </div>

//     ) : (

//     <Form layout="vertical" style={{ overflowY: 'scroll', height: '250px'}}>
//       <Row style={{ fontSize: '25px' }} >Control Riesgos</Row>
//       <hr/>
//       { dataTipoFormulario !== 'PT Confinado' &&  (
//         <>
//       <Row gutter={16}>
//         <Col style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}} >
//           EPP
//         </Col>
//       </Row>      
//       <hr/>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Protección Cabeza" >
//             <Input
//                 name="proteccioncabeza"
//                 value={formik.values.proteccioncabeza}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Protección facial" >
//             <Input
//                 name="proteccionfacial"
//                 value={formik.values.proteccionfacial}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Protección visual" >
//             <Input
//                 name="proteccionvisual"
//                 value={formik.values.proteccionvisual}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
      
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Protección respiratoria" >
//             <Input
//                 name="proteccionrespiratoria"
//                 value={formik.values.proteccionrespiratoria}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Protección respiratoria" >
//             <Input
//                 name="proteccionrespiratoria"
//                 value={formik.values.proteccionrespiratoria}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Protección auditiva" >
//             <Input
//                 name="proteccionauditiva"
//                 value={formik.values.proteccionauditiva}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Protección manual" >
//             <Input
//                 name="proteccionmanual"
//                 value={formik.values.proteccionmanual}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Protección pies" >
//             <Input
//                 name="proteccionpies"
//                 value={formik.values.proteccionpies}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Ropa de protección" >
//             <Input
//                 name="ropaproteccion"
//                 value={formik.values.ropaproteccion}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         { dataTipoFormulario !== 'ATS' &&  (
//         <Col span={8}>
//           <Form.Item label="Sistema de bloqueo y etiquetado" >
//             <Input
//                 name="sistemabloeti"
//                 value={formik.values.sistemabloeti}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         )}
//         <Col span={8}>
//           <Form.Item label="Accesorios adicionales (Cinturon, etc)" >
//             <Input
//                 name="accesorioadicional"
//                 value={formik.values.accesorioadicional}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//             <Form.Item label="Otra protección especial" >
//                 <Input
//                     name="otraproteccion"
//                     value={formik.values.otraproteccion}
//                     readOnly
//                 />
//             </Form.Item>
//         </Col>
//       </Row>

//       { dataTipoFormulario !== 'ATS' &&  (
//       <>
//       <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>EPP contra caidas</Col></Row>
//       <hr/>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Eslinga con absorvedor de impacto" >
//             <Input
//                 name="esligaimpacto"
//                 value={formik.values.esligaimpacto}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Eslinga de posicionamiento" >
//             <Input
//                 name="eslingaposicionamiento"
//                 value={formik.values.eslingaposicionamiento}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Eslinga en Y con amortiguador de impacto" >
//             <Input
//                 name="eslingay"
//                 value={formik.values.eslingay}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Eslinga en Y de 60 cm sin amortiguador" >
//             <Input
//                 name="esliga60"
//                 value={formik.values.esliga60}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Tie off / anclaje portátil" >
//             <Input
//                 name="tieoff"
//                 value={formik.values.tieoff}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Sistema de línea de vida vertical portátil y mosquetón" >
//             <Input
//                 name="lvvertical"
//                 value={formik.values.lvvertical}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
      
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Sistema de línea de vida horizontal portátil" >
//             <Input
//                 name="lvhorizontal"
//                 value={formik.values.lvhorizontal}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Punto de anclaje fijo" >
//             <Input
//                 name="puntoanclaje"
//                 value={formik.values.puntoanclaje}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
//       </>
//       )}

//       { dataTipoFormulario !== 'PT Alturas' &&  (
//        <>
//       <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Otros controles</Col></Row>
//       <hr/>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Procedimiento de trabajo seguro" >
//             <Input
//                 name="proctrabajo"
//                 value={formik.values.proctrabajo}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Duchas y lavaojos" >
//             <Input
//                 name="duchas"
//                 value={formik.values.duchas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Sistema de acceso trabajo en alturas" >
//             <Input
//                 name="trabajoalturas"
//                 value={formik.values.trabajoalturas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Bloqueo y etiquetado" >
//             <Input
//                 name="bloqueoetiquetado"
//                 value={formik.values.bloqueoetiquetado}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Permiso de trabajo en frio" >
//             <Input
//                 name="trabajofrio"
//                 value={formik.values.trabajofrio}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Equipos de control de incendios" >
//             <Input
//                 name="controlincedios"
//                 value={formik.values.controlincedios}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Señalización y demarcación" >
//             <Input
//                 name="senalizacion"
//                 value={formik.values.senalizacion}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Procedimiento de evaluación / MEDEVAC" >
//             <Input
//                 name="medevac"
//                 value={formik.values.medevac}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Sistema de protección contra caidas" >
//             <Input
//                 name="proteccioncaidas"
//                 value={formik.values.proteccioncaidas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Monitoreo continuo de explosividad" >
//             <Input
//                 name="explosividad"
//                 value={formik.values.explosividad}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Permiso de trabajo en caliente" >
//             <Input
//                 name="trabajocaliente"
//                 value={formik.values.trabajocaliente}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Procedimiento de control de derrames" >
//             <Input
//                 name="controlderrames"
//                 value={formik.values.controlderrames}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Hojas de seguridad" >
//             <Input
//                 name="hojaseguridad"
//                 value={formik.values.hojaseguridad}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Permiso de trabajo en alturas" >
//             <Input
//                 name="trabajoaltura"
//                 value={formik.values.trabajoaltura}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Equipo de primeros auxilios" >
//             <Input
//                 name="primerosauxilios"
//                 value={formik.values.primerosauxilios}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Barrera física" >
//             <Input
//                 name="barrerafisica"
//                 value={formik.values.barrerafisica}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Permiso de trabajo espacios confinados" >
//             <Input
//                 name="espacioconfinado"
//                 value={formik.values.espacioconfinado}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Supervisor / Vigia HSE con licencia" >
//             <Input
//                 name="vigiahse"
//                 value={formik.values.vigiahse}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="By-pass, desgasificación" >
//             <Input
//                 name="bypass"
//                 value={formik.values.bypass}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Permiso de trabajo eléctrico" >
//             <Input
//                 name="trabajoelectrico"
//                 value={formik.values.trabajoelectrico}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Otros (controles)" >
//             <Input
//                 name="otroscontroles"
//                 value={formik.values.otroscontroles}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
//       </>
//       )}
//       </>
//       )}
//       { dataTipoFormulario !== 'PT Alturas' &&  (
//        <>
//       <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Equipo de seguridad requerido</Col></Row>
//       <hr/>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Casco de seguridad no dielectrico" >
//             <Input
//                 name="casconodielectrico"
//                 value={formik.values.casconodielectrico}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Casco de seguridad dielectrico" >
//             <Input
//                 name="cascodielectrico"
//                 value={formik.values.cascodielectrico}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Botas de seguridad no dielectricas" >
//             <Input
//                 name="botasnodielectricas"
//                 value={formik.values.botasnodielectricas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Botas de seguridad dielectricas" >
//             <Input
//                 name="botasdielectricas"
//                 value={formik.values.botasdielectricas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Gafas de seguridad" >
//             <Input
//                 name="gafaseguridad"
//                 value={formik.values.gafaseguridad}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Careta de soldador" >
//             <Input
//                 name="caretasoldador"
//                 value={formik.values.caretasoldador}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Traje impermeable" >
//             <Input
//                 name="trajeimpermeable"
//                 value={formik.values.trajeimpermeable}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Chaleco reflectivo / Salvavidas" >
//             <Input
//                 name="chalecoreflectivo"
//                 value={formik.values.chalecoreflectivo}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Peto, polaina y mangas para soldar" >
//             <Input
//                 name="petomangas"
//                 value={formik.values.petomangas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Protección auditiva de inserción" >
//             <Input
//                 name="protauditivainsercion"
//                 value={formik.values.protauditivainsercion}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Protección auditiva tipo copa" >
//             <Input
//                 name="protauditivacopa"
//                 value={formik.values.protauditivacopa}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Overol" >
//             <Input
//                 name="overol"
//                 value={formik.values.overol}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Guantes de cuero (Vaqueta)" >
//             <Input
//                 name="guantescuero"
//                 value={formik.values.guantescuero}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Guantes de hilaza con puntos" >
//             <Input
//                 name="guanteshilaza"
//                 value={formik.values.guanteshilaza}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Guantes de nitrilo" >
//             <Input
//                 name="guantesnitrilo"
//                 value={formik.values.guantesnitrilo}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Guantes de latex" >
//             <Input
//                 name="guanteslatex"
//                 value={formik.values.guanteslatex}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Guantes de poliuretano" >
//             <Input
//                 name="guantespoliuretano"
//                 value={formik.values.guantespoliuretano}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Tapabocas" >
//             <Input
//                 name="tapabocas"
//                 value={formik.values.tapabocas}
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Detector LEL / Oxigeno" >
//             <Input
//                 name="detectorlel"
//                 value={formik.values.detectorlel}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Linea de vida" >
//             <Input
//                 name="lineavida"
//                 value={formik.values.lineavida}
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Eslingas" >
//             <Input
//                 name="eslingas"
//                 value={formik.values.eslingas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
 
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Arnés cuerpo completo" >
//             <Input
//                 name="arnescuerpo"
//                 value={formik.values.arnescuerpo}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Respirador gases y vapores" >
//             <Input
//                 name="respiradorgases"
//                 value={formik.values.respiradorgases}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Respirador humos metalicos" >
//             <Input
//                 name="respiradorhumos"
//                 value={formik.values.respiradorhumos}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Respirador polvos / Nieblas" >
//             <Input
//                 name="respiradorpolvos"
//                 value={formik.values.respiradorpolvos}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Respirador libre mantenimiento con valvula" >
//             <Input
//                 name="respiradorlibre"
//                 value={formik.values.respiradorlibre}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Otros (Equipos de seguridad)" >
//             <Input
//                 name="otrosseguridad"
//                 value={formik.values.otrosseguridad}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
//       </>
//       )}
//     </Form>
//     )}
//     </>
//   );
// }