import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Col, Form, Input, Row, message, Select, Table } from "antd";
import { useFormik } from "formik";
import { FormStyle } from "../../../comons/styles";
import { useCondicionesSalud } from "../../../../hooks/administrativa/formularios-sig/useCondicionesSalud";
import { initialValuesCondicionSalud } from "../components/initialValues";
import { CircularProgress } from "@mui/material";

export function CondicionSaludForm(props) {

  const { updateCondicionesSalud, cancelLoader, loading } = useCondicionesSalud();
  const { dataTipoFormulario, data, handleNext, handlePrev, id, setnewdataCondicionesSalud } = props;
  const { Option } = Select;

  useEffect(() => {
    cancelLoader()
  }, [data])

  const formik = useFormik({
    initialValues: initialValuesCondicionSalud(id.Id_formulario, data),
    //validationSchema: analisisTrabajoSchema,
    onSubmit: async (formValues) => {
      try {
        await updateCondicionesSalud(id.Id_condicionessalud, formValues);
        message.success("Actualización exitosa (Condiciones salud)");
        setnewdataCondicionesSalud(formValues)
        handleNext()
      } catch (error) {
        message.error("Error en la operación (Condiciones salud)");
      }
    },
  });

  return (
    <>
    {loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </div>
    ) : (
    <Form layout="vertical" onFinish={formik.handleSubmit} style={FormStyle}>
      <Row gutter={16}>
        <Col style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}} >
          Condiciones Salud
        </Col>
      </Row>      
      <hr/>

      <Row gutter={24}>
        <Col span={11}>
          <Form.Item
              label="¿Está en condiciones óptimas de salud para realizar el trabajo en alturas?"
              validateStatus={formik.errors.condicionesoptimas && formik.touched.condicionesoptimas ? "error" : ""}
              help={formik.touched.condicionesoptimas && formik.errors.condicionesoptimas ? formik.errors.condicionesoptimas : ""}
          >
            <Select
                name="condicionesoptimas"
                value={formik.values.condicionesoptimas ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('condicionesoptimas', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
        </Form.Item>
    </Col>
    <Col span={13}>
        <Form.Item
            label="¿Toma medicamentos que causen sueño o alteración de la conciencia, ej: antigripales, antidepresivos, etc.?"
            validateStatus={formik.errors.medicamentossueno && formik.touched.medicamentossueno ? "error" : ""}
            help={formik.touched.medicamentossueno && formik.errors.medicamentossueno ? formik.errors.medicamentossueno : ""}
        >
            <Select
                name="medicamentossueno"
                value={formik.values.medicamentossueno ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('medicamentossueno', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
        </Form.Item>
    </Col>
</Row>

<Row gutter={16}>
    <Col span={8}>
        <Form.Item
            label="¿Consume drogas alucinógenas?"
            validateStatus={formik.errors.drogasalucinogenas && formik.touched.drogasalucinogenas ? "error" : ""}
            help={formik.touched.drogasalucinogenas && formik.errors.drogasalucinogenas ? formik.errors.drogasalucinogenas : ""}
        >
            <Select
                name="drogasalucinogenas"
                value={formik.values.drogasalucinogenas ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('drogasalucinogenas', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
        </Form.Item>
    </Col>
    <Col span={8}>
        <Form.Item
            label="¿Sufre de epilepsia, mareo o vértigo?"
            validateStatus={formik.errors.epilepsia && formik.touched.epilepsia ? "error" : ""}
            help={formik.touched.epilepsia && formik.errors.epilepsia ? formik.errors.epilepsia : ""}
        >
            <Select
                name="epilepsia"
                value={formik.values.epilepsia ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('epilepsia', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
        </Form.Item>
    </Col>
    <Col span={8}>
        <Form.Item
            label="¿Le tiene fobia a las alturas (acrofobia)?"
            validateStatus={formik.errors.fobiaalturas && formik.touched.fobiaalturas ? "error" : ""}
            help={formik.touched.fobiaalturas && formik.errors.fobiaalturas ? formik.errors.fobiaalturas : ""}
        >
            <Select
                name="fobiaalturas"
                value={formik.values.fobiaalturas ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('fobiaalturas', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
        </Form.Item>
    </Col>
</Row>

<Row gutter={24}>
    <Col span={12}>
        <Form.Item
            label="¿Ha recibido capacitación y entrenamiento en trabajo en alturas?"
            validateStatus={formik.errors.capacitacion && formik.touched.capacitacion ? "error" : ""}
            help={formik.touched.capacitacion && formik.errors.capacitacion ? formik.errors.capacitacion : ""}
        >
            <Select
                name="capacitacion"
                value={formik.values.capacitacion ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('capacitacion', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
        </Form.Item>
    </Col>
    <Col span={12}>
        <Form.Item
            label="¿Se encuentra certificado en trabajo en alturas?"
            validateStatus={formik.errors.certificadoaltura && formik.touched.certificadoaltura ? "error" : ""}
            help={formik.touched.certificadoaltura && formik.errors.certificadoaltura ? formik.errors.certificadoaltura : ""}
        >
            <Select
                name="certificadoaltura"
                value={formik.values.certificadoaltura ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('certificadoaltura', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
       
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="¿Seguridad social vigente?"
              validateStatus={formik.errors.ssvigente && formik.touched.ssvigente ? "error" : ""}
              help={formik.touched.ssvigente && formik.errors.ssvigente ? formik.errors.ssvigente : ""}
          >
            <Select
                name="ssvigente"
                value={formik.values.ssvigente ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('ssvigente', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
        </Form.Item>
    </Col>
</Row>

{dataTipoFormulario !== 'PT Confinado' && (
<>
    <Row gutter={16}>
        <Col span={8} style={{fontSize: '20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>
            Peligros de exposición
        </Col>
    </Row>
    <hr/>
    <Row gutter={24}>
        <Col span={8}>
            <Form.Item
                label="Deficiencia de oxígeno (menos del 19,5%)"
                validateStatus={formik.errors.deficienciaoxigeno && formik.touched.deficienciaoxigeno ? "error" : ""}
                help={formik.touched.deficienciaoxigeno && formik.errors.deficienciaoxigeno ? formik.errors.deficienciaoxigeno : ""}
            >
                <Select
                    name="deficienciaoxigeno"
                    value={formik.values.deficienciaoxigeno ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('deficienciaoxigeno', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
            </Form.Item>
        </Col>
        <Col span={16}>
            <Form.Item
                label="Gases o vapores inflamables (más del 10% del Límite Inferior de Inflamabilidad)"
                validateStatus={formik.errors.gasesinflamables && formik.touched.gasesinflamables ? "error" : ""}
                help={formik.touched.gasesinflamables && formik.errors.gasesinflamables ? formik.errors.gasesinflamables : ""}
            >
                <Select
                    name="gasesinflamables"
                    value={formik.values.gasesinflamables ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('gasesinflamables', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
            </Form.Item>
        </Col>
    </Row>

    <Row gutter={24}>
        <Col span={8}>
            <Form.Item
                label="Exceso de oxígeno (más del 23,5%)"
                validateStatus={formik.errors.excesooxigeno && formik.touched.excesooxigeno ? "error" : ""}
                help={formik.touched.excesooxigeno && formik.errors.excesooxigeno ? formik.errors.excesooxigeno : ""}
            >
                <Select
                    name="excesooxigeno"
                    value={formik.values.excesooxigeno ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('excesooxigeno', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
            </Form.Item>
        </Col>
        <Col span={16}>
            <Form.Item
                label="Partículas de polvo inflamables en la atmósfera (iguala o excede el LFL)"
                validateStatus={formik.errors.particulapolvo && formik.touched.particulapolvo ? "error" : ""}
                help={formik.touched.particulapolvo && formik.errors.particulapolvo ? formik.errors.particulapolvo : ""}
            >
                <Select
                    name="particulapolvo"
                    value={formik.values.particulapolvo ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('particulapolvo', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
            </Form.Item>
        </Col>
    </Row>

    <Row gutter={24}>
        <Col span={16}>
            <Form.Item
                label="Gases o vapores tóxicos (nivel mayor al Límite Permisible de Exposición)"
                validateStatus={formik.errors.vaporestoxicos && formik.touched.vaporestoxicos ? "error" : ""}
                help={formik.touched.vaporestoxicos && formik.errors.vaporestoxicos ? formik.errors.vaporestoxicos : ""}
            >
                <Select
                    name="vaporestoxicos"
                    value={formik.values.vaporestoxicos ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('vaporestoxicos', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
            </Form.Item>
        </Col>
        <Col span={8}>
            <Form.Item
                label="Peligros mecánicos"
                validateStatus={formik.errors.peligrosmecanicos && formik.touched.peligrosmecanicos ? "error" : ""}
                help={formik.touched.peligrosmecanicos && formik.errors.peligrosmecanicos ? formik.errors.peligrosmecanicos : ""}
            >
                <Select
                    name="peligrosmecanicos"
                    value={formik.values.peligrosmecanicos ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('peligrosmecanicos', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
            </Form.Item>
        </Col>
    </Row>

    <Row gutter={16}>
        <Col span={8}>
            <Form.Item
                label="Choque eléctrico"
                validateStatus={formik.errors.choqueelectrico && formik.touched.choqueelectrico ? "error" : ""}
                help={formik.touched.choqueelectrico && formik.errors.choqueelectrico ? formik.errors.choqueelectrico : ""}
            >
                <Select
                    name="choqueelectrico"
                    value={formik.values.choqueelectrico ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('choqueelectrico', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
            </Form.Item>
        </Col>
        <Col span={8}>
            <Form.Item
                label="Materiales peligrosos para la piel"
                validateStatus={formik.errors.peligropiel && formik.touched.peligropiel ? "error" : ""}
                help={formik.touched.peligropiel && formik.errors.peligropiel ? formik.errors.peligropiel : ""}
            >
                <Select
                    name="peligropiel"
                    value={formik.values.peligropiel ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('peligropiel', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
            </Form.Item>
        </Col>
        <Col span={8}>
            <Form.Item
                label="Atrapamiento"
                validateStatus={formik.errors.atrapamiento && formik.touched.atrapamiento ? "error" : ""}
                help={formik.touched.atrapamiento && formik.errors.atrapamiento ? formik.errors.atrapamiento : ""}
            >
                <Select
                    name="atrapamiento"
                    value={formik.values.atrapamiento ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('atrapamiento', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Otros, Cual"
              validateStatus={formik.errors.otrospeligros && formik.touched.otrospeligros ? "error" : ""}
              help={formik.touched.otrospeligros && formik.errors.otrospeligros ? formik.errors.otrospeligros : ""}
          >
            <Input
                name="otrospeligros"
                value={formik.values.otrospeligros}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
      </>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="primary" htmlType="button" onClick={handlePrev}>
            Anterior
          </Button>
          <Button type="primary" htmlType="submit">
            Actualizar
          </Button>
          <Button type="primary" style={{ background: "black", marginLeft: "5px" }} onClick={handleNext}>
            Siguiente
          </Button>
      </div>
    </Form>
    )}
    </>
  );
}

export function CondicionSaludFullView(props) {
  const { cancelLoader, loading } = useCondicionesSalud();
  const { dataTipoFormulario, data } = props;

  useEffect(() => {
      cancelLoader();
  }, [data]);

  const formik = useFormik({
      initialValues: initialValuesCondicionSalud(data.Id_formulario, data),
  });

  const columns = [
      { dataIndex: 'campo1', key: 'campo1', width: '50%', render: text => <div style={{ fontSize: '12px' }}>{text}</div> },
      { dataIndex: 'campo2', key: 'campo2', width: '50%', render: text => <div style={{ fontSize: '12px' }}>{text}</div> },
  ];

  const dataSource = [
      {
          key: '1',
          campo1: '¿Está en condiciones óptimas de salud para realizar el trabajo en alturas?',
          campo2: formik.values.condicionesoptimas ? 'Sí' : 'No',
      },
      {
          key: '2',
          campo1: '¿Toma medicamentos que causen sueño o alteración de la conciencia, ej: antigripales, antidepresivos, etc.?',
          campo2: formik.values.medicamentossueno ? 'Sí' : 'No',
      },
      {
          key: '3',
          campo1: '¿Consume drogas alucinógenas?',
          campo2: formik.values.drogasalucinogenas ? 'Sí' : 'No',
      },
      {
          key: '4',
          campo1: '¿Sufre de epilepsia, mareo o vértigo?',
          campo2: formik.values.epilepsia ? 'Sí' : 'No',
      },
      {
          key: '5',
          campo1: '¿Le tiene fobia a las alturas (acrofobia)?',
          campo2: formik.values.fobiaalturas ? 'Sí' : 'No',
      },
      {
          key: '6',
          campo1: '¿Ha recibido capacitación y entrenamiento en trabajo en alturas?',
          campo2: formik.values.capacitacion ? 'Sí' : 'No',
      },
      {
          key: '7',
          campo1: '¿Se encuentra certificado en trabajo en alturas?',
          campo2: formik.values.certificadoaltura ? 'Sí' : 'No',
      },
      {
          key: '8',
          campo1: '¿Seguridad social vigente?',
          campo2: formik.values.ssvigente ? 'Sí' : 'No',
      },
      {
          key: '9',
          campo1: 'Deficiencia de oxígeno (menos del 19,5%)',
          campo2: formik.values.deficienciaoxigeno ? 'Sí' : 'No',
      },
      {
          key: '10',
          campo1: 'Gases o vapores inflamables (más del 10% del Límite Inferior de Inflamabilidad)',
          campo2: formik.values.gasesinflamables ? 'Sí' : 'No',
      },
      {
          key: '11',
          campo1: 'Exceso de oxígeno (más del 23,5%)',
          campo2: formik.values.excesooxigeno ? 'Sí' : 'No',
      },
      {
          key: '12',
          campo1: 'Partículas de polvo inflamables en la atmósfera (iguala o excede el LFL)',
          campo2: formik.values.particulapolvo ? 'Sí' : 'No',
      },
      {
          key: '13',
          campo1: 'Gases o vapores tóxicos (nivel mayor al Límite Permisible de Exposición)',
          campo2: formik.values.vaporestoxicos ? 'Sí' : 'No',
      },
      {
          key: '14',
          campo1: 'Peligros mecánicos',
          campo2: formik.values.peligrosmecanicos ? 'Sí' : 'No',
      },
      {
          key: '15',
          campo1: 'Choque eléctrico',
          campo2: formik.values.choqueelectrico ? 'Sí' : 'No',
      },
      {
          key: '16',
          campo1: 'Materiales peligrosos para la piel',
          campo2: formik.values.peligropiel ? 'Sí' : 'No',
      },
      {
          key: '17',
          campo1: 'Atrapamiento',
          campo2: formik.values.atrapamiento ? 'Sí' : 'No',
      },
      {
          key: '18',
          campo1: 'Otros, Cual',
          campo2: formik.values.otrospeligros || '',
      },
  ];

  // Función para determinar la clase de la fila
  const getRowClassName = (record) => {
      const grayKeys = ['1', '3', '5', '7', '9', '11', '13', '15', '17']; // Claves para las filas que tendrán fondo gris
      return grayKeys.includes(record.key) ? 'gray-background' : '';
  };

  const formStyle = { fontSize: '16px', overflowY: 'scroll', height: 'calc(100vh - 50px)' };

  return (
      <>
          {loading ? (
              <div
                  style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                  }}
              >
                  <CircularProgress />
              </div>
          ) : (
              <>
                  <Row style={{ fontSize: '25px' }}>Condiciones de salud</Row>
                  <hr />
                  {dataTipoFormulario !== 'PT Confinado' && (
                      <Table
                          columns={columns}
                          dataSource={dataSource}
                          pagination={false}
                          showHeader={false}
                          bordered
                          rowClassName={getRowClassName}
                          className="table-narrow"
                      />
                  )}
              </>
          )}
      </>
  );
}

// export function CondicionSaludFullView(props) {
  
//   const { cancelLoader, loading } = useCondicionesSalud();
//   const { dataTipoFormulario, data } = props;

//   useEffect(() => {
//     cancelLoader()
//   }, [data])
  

//   const formik = useFormik({
//     initialValues: initialValuesCondicionSalud(data.Id_formulario, data),
//   });

//   return (
//     <>
//     {loading ? (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     ) : (
//     <Form layout="vertical" style={formStyle}>
//       <Row style={{ fontSize: '25px' }} >Condiciones de salud</Row>
//       <hr/>
//       <Row gutter={16}>
//         <Col style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}} >
//           Condiciones Salud
//         </Col>
//       </Row>      
//       <hr/>

//       <Row gutter={24}>
//         <Col span={11}>
//           <Form.Item label="¿Está en condiciones óptimas de salud para realizar el trabajo en alturas?" >
//             <Input
//                 name="condicionesoptimas"
//                 value={formik.values.condicionesoptimas ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={13}>
//           <Form.Item label="¿Toma medicamentos que causen sueño o alteración de la conciencia, ej: antigripales, antidepresivos, etc.?" >
//             <Input
//                 name="medicamentossueno"
//                 value={formik.values.medicamentossueno ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
      
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="¿Consume drogas alucinógenas?" >
//             <Input
//                 name="drogasalucinogenas"
//                 value={formik.values.drogasalucinogenas ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="¿Sufre de epilepsia, mareo o vértigo?" >
//             <Input
//                 name="epilepsia"
//                 value={formik.values.epilepsia ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="¿Le tiene fobia a las alturas (acrofobia)?" >
//             <Input
//                 name="fobiaalturas"
//                 value={formik.values.fobiaalturas ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={24}>
//         <Col span={12}>
//           <Form.Item label="¿Ha recibido capacitación y entrenamiento en trabajo en alturas?" >
//             <Input
//                 name="capacitacion"
//                 value={formik.values.capacitacion ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={12}>
//           <Form.Item label="¿Se encuentra certificado en trabajo en alturas?" >
//             <Input
//                 name="certificadoaltura"
//                 value={formik.values.certificadoaltura ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
       
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="¿Seguridad social vigente?" >
//             <Input
//                 name="ssvigente"
//                 value={formik.values.ssvigente ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       { dataTipoFormulario !== 'PT Confinado' &&  (
//       <>
//       <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Peligros de exposición</Col></Row>
//       <hr/>
//       <Row gutter={24}>
//         <Col span={8}>
//           <Form.Item label="Deficiencia de oxígeno (menos del 19,5%)" >
//             <Input
//                 name="deficienciaoxigeno"
//                 value={formik.values.deficienciaoxigeno ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={16}>
//           <Form.Item label="Gases o vapores inflamables (más del 10% del Límite Inferior de Inflamabilidad)" >
//             <Input
//                 name="gasesinflamables"
//                 value={formik.values.gasesinflamables ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={24}>
//         <Col span={8}>
//           <Form.Item label="Exceso de oxígeno (más del 23,5%)" >
//             <Input
//                 name="excesooxigeno"
//                 value={formik.values.excesooxigeno ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={16}>
//           <Form.Item label="Partículas de polvo inflamables en la atmósfera (iguala o excede el LFL)" >
//             <Input
//                 name="particulapolvo"
//                 value={formik.values.particulapolvo ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={24}>
//         <Col span={16}>
//           <Form.Item label="Gases o vapores tóxicos (nivel mayor al Límite Permisible de Exposición)" >
//             <Input
//                 name="vaporestoxicos"
//                 value={formik.values.vaporestoxicos ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Peligros mecánicos" >
//             <Input
//                 name="peligrosmecanicos"
//                 value={formik.values.peligrosmecanicos ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Choque eléctrico" >
//             <Input
//                 name="choqueelectrico"
//                 value={formik.values.choqueelectrico ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Materiales peligrosos para la piel" >
//             <Input
//                 name="peligropiel"
//                 value={formik.values.peligropiel ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Atrapamiento" >
//             <Input
//                 name="atrapamiento"
//                 value={formik.values.atrapamiento ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Otros, Cual" >
//             <Input
//                 name="otrospeligros"
//                 value={formik.values.otrospeligros}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
//       </>
//       )}
//     </Form>
//     )}
//     </>
//   );
// }