//Librerias y hooks(React) necesarias para función de los componentes
import React, { useState, useEffect, useRef} from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { message, Button, Space, Form, Row, Col } from 'antd';
import { CircularProgress } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { useFormik } from 'formik';
import logosyz from '../../../../assets/logo.png';


//Hooks para traer datos de los formularios adyacentes al formulario de datos principales

import { 
  useAccesoPrevencion, 
  useAnalisisTrabajo, 
  useCondicionesSalud, 
  useControlRiesgos, 
  useInspeccionAltura,
  useQuienDifunde,
  useQuienElabora,
  useRiesgoPotencial,
  useSuperCoord,
  useVerificacionDocs,
  useFormularioSig,

} from '../../../../hooks/administrativa/formularios-sig/index';

//Componentes de los formularios que se usarán en los steps

import {
  AccesoPrevencionForm,
  AccesoPrevencionFullView,
  AnalisisTrabajoForm,
  AnalisisTrabajoFullView,
  CondicionSaludForm,
  CondicionSaludFullView,
  ControlRiesgosForm,
  ControlRiesgosFullView,
  DatosActividadFormUpdate,
  DatosActividadFullView,
  QuienDifundeForm,
  QuienDifundeFullView,
  InspeccionAlturaForm,
  InspeccionAlturaFullView,
  QuienElaboraForm,
  QuienElaboraFullView,
  RiesgoPotencialForm,
  RiesgoPotencialFullView,
  SupervisorForm,
  SupervisorFullView,
  VerificacionDocForm,
  VerificacionDocFullView,


} from './index';
import { useAuth } from '../../../../hooks/useAuth';

export function FormularioSigFormUpdate(props) {
  const { FormularioSigUpdate, onRefetch, onClose } = props;
  const [activeStep, setActiveStep] = useState(0);
  const tipoFormularioPrincipal = FormularioSigUpdate.tipoformulario;
  const idFormularioPrincipal = FormularioSigUpdate.Id_formulario;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showSteps, setShowSteps] = useState(false);

  const { getAccesoPrevencionID, AccesoPrevencion } = useAccesoPrevencion();
  const { getAnalisisRiesgosID, AnalisisTrabajo } = useAnalisisTrabajo();
  const { getCondicionesSaludID, CondicionesSalud } = useCondicionesSalud();
  const { getControlRiesgosID, ControlRiesgos } = useControlRiesgos();
  const { getInspeccionAlturasID, InspeccionAltura } = useInspeccionAltura();
  const { getQuienDifundeID, QuienDifunde } = useQuienDifunde();
  const { getQuienElaboraID, QuienElabora } = useQuienElabora();
  const { getRiesgoPotencialID, RiesgoPotencial } = useRiesgoPotencial();
  const { getSuperCoordID, SuperCoord } = useSuperCoord();
  const { getVerificacionDocsID, VerificacionDoc } = useVerificacionDocs();

  const [ newdataAccesoPrevencion, setnewdataAccesoPrevencion ] = useState(AccesoPrevencion)
  const [ newdataAnalisisTrabajo, setnewdatanewdataAnalisisTrabajo ] = useState(AnalisisTrabajo)
  const [ newdataCondicionesSalud, setnewdataCondicionesSalud ] = useState(CondicionesSalud)
  const [ newdataControlRiesgos, setnewdataControlRiesgos ] = useState(ControlRiesgos)
  const [ newdataDatosActividad, setnewdataDatosActividad ] = useState(FormularioSigUpdate)
  const [ newdataDifundidoA, setnewdataDifundidoA ] = useState(QuienDifunde)
  const [ newdataInspeccionAltura, setnewdataInspeccionAltura ] = useState(InspeccionAltura)
  const [ newdataQuienElabora, setnewdataQuienElabora ] = useState(QuienElabora)
  const [ newdataRiesgoPotencial, setnewdataRiesgoPotencial ] = useState(RiesgoPotencial)
  const [ newdataSupervisor, setnewdataSupervisor ] = useState(SuperCoord)
  const [ newdataVerificacionDoc, setnewdataVerificacionDoc ] = useState(VerificacionDoc)

  
  const handleTableRefresh = () => {
    onRefetch();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (tipoFormularioPrincipal === "ATS") {
          await Promise.all([
            getRiesgoPotencialID(idFormularioPrincipal),
            getControlRiesgosID(idFormularioPrincipal),
            getVerificacionDocsID(idFormularioPrincipal),
            getQuienElaboraID(idFormularioPrincipal),
            getQuienDifundeID(idFormularioPrincipal),
            getAnalisisRiesgosID(idFormularioPrincipal),
            getSuperCoordID(idFormularioPrincipal),
          ]);
        } else if (tipoFormularioPrincipal === "PT Alturas") {
          await Promise.all([
            getControlRiesgosID(idFormularioPrincipal),
            getCondicionesSaludID(idFormularioPrincipal),
            getAccesoPrevencionID(idFormularioPrincipal),
            getInspeccionAlturasID(idFormularioPrincipal),
            getAnalisisRiesgosID(idFormularioPrincipal),
            getSuperCoordID(idFormularioPrincipal),
          ]);
        } else if (tipoFormularioPrincipal === "PT Confinado") {
          await Promise.all([
            getCondicionesSaludID(idFormularioPrincipal),
            getControlRiesgosID(idFormularioPrincipal),
            getAccesoPrevencionID(idFormularioPrincipal),
            getAnalisisRiesgosID(idFormularioPrincipal),
            getSuperCoordID(idFormularioPrincipal),
          ]);
        }
        setDataLoaded(true);
      } catch (error) {
        message.error('Error al cargar datos');
      }
    };
  
    fetchData();
  }, [idFormularioPrincipal, tipoFormularioPrincipal]);


  useEffect(() => {
      setnewdataAccesoPrevencion(AccesoPrevencion)
  }, [AccesoPrevencion])

    useEffect(() => {
      setnewdatanewdataAnalisisTrabajo(AnalisisTrabajo)
  }, [AnalisisTrabajo])

  useEffect(() => {
    setnewdataCondicionesSalud(CondicionesSalud)
  }, [CondicionesSalud])

  useEffect(() => {
    setnewdataControlRiesgos(ControlRiesgos)
  }, [ControlRiesgos])

  useEffect(() => {
    setnewdataDifundidoA(QuienDifunde)
  }, [QuienDifunde])

  useEffect(() => {
    setnewdataInspeccionAltura(InspeccionAltura)
  }, [InspeccionAltura])

  useEffect(() => {
    setnewdataQuienElabora(QuienElabora)
  }, [QuienElabora])

  useEffect(() => {
    setnewdataRiesgoPotencial(RiesgoPotencial)
  }, [RiesgoPotencial])

  useEffect(() => {
    setnewdataSupervisor(SuperCoord)
  }, [SuperCoord])

  useEffect(() => {
    setnewdataVerificacionDoc(VerificacionDoc)
  }, [VerificacionDoc])
  
  useEffect(() => {
    if (dataLoaded) {
      setShowSteps(true);
    }
  }, [dataLoaded]);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  
  const StepsATS = [
    { 
      title: 'Datos Actividad', 
      component: (
        <DatosActividadFormUpdate
          FormularioSigUpdate={newdataDatosActividad}
          onRefetch={onRefetch}
          onTableRefresh={handleTableRefresh}
          handleNext={handleNext}
          dataTipoFormulario={tipoFormularioPrincipal}
          idFormularioPrincipal={idFormularioPrincipal}
          setnewData={setnewdataDatosActividad}
        />
      )
    },
    {
      title: 'Riesgos Potenciales', 
      component: (
        <RiesgoPotencialForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataRiesgoPotencial}
          onTableRefresh={handleTableRefresh}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={RiesgoPotencial}
          setnewdataRiesgoPotencial={setnewdataRiesgoPotencial}
        />
      )
    },
    {
      title: 'Control de Riesgos', 
      component: (
        <ControlRiesgosForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataControlRiesgos}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={ControlRiesgos}
          setnewdataControlRiesgos={setnewdataControlRiesgos}
        />
      )
    },
    { 
      title: 'Análisis de Trabajo', 
      component: (
        <AnalisisTrabajoForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataAnalisisTrabajo}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={AnalisisTrabajo}
          setnewdatanewdataAnalisisTrabajo={setnewdatanewdataAnalisisTrabajo}
        />
      )
    },
    {
      title: 'Verificación de Documento', 
      component: (
        <VerificacionDocForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataVerificacionDoc}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={VerificacionDoc}
          setnewdataVerificacionDoc={setnewdataVerificacionDoc}
        />
      )
    },
    {
      title: 'Equipo que elabora', 
      component: (
        <QuienElaboraForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataQuienElabora}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={QuienElabora}
          setnewdataQuienElabora={setnewdataQuienElabora}
        />
      )
    },
    {
      title: 'Difundido A', 
      component: (
        <QuienDifundeForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataDifundidoA}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={QuienDifunde}
          setnewdataDifundidoA={setnewdataDifundidoA}
        />
      )
    },
    { 
      title: 'Aprobador', 
      component: (
        <SupervisorForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataSupervisor}
          onTableRefresh={handleTableRefresh}
          onClose={onClose}
          handlePrev={handlePrev}
          id={SuperCoord}
          setnewdataSupervisor={setnewdataSupervisor}
        />
      )
    },
  ];

  const StepsPTA = [
    { 
      title: 'Datos Actividad', 
      component: (
        <DatosActividadFormUpdate
          FormularioSigUpdate={newdataDatosActividad}
          onRefetch={onRefetch}
          onTableRefresh={handleTableRefresh}
          handleNext={handleNext}
          idFormularioPrincipal={idFormularioPrincipal}
          setnewData={setnewdataDatosActividad}
        />
      )
    },
    {
      title: 'Condiciones de Salud', 
      component: (
        <CondicionSaludForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataCondicionesSalud}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={CondicionesSalud}
          setnewdataCondicionesSalud={setnewdataCondicionesSalud}
        />
      )
    },
    {
      title: 'Control de Riesgos', 
      component: (
        <ControlRiesgosForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataControlRiesgos}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={ControlRiesgos}
          setnewdataControlRiesgos={setnewdataControlRiesgos}
        />
      )
    },
    {
      title: 'Acceso y Prevención', 
      component: (
        <AccesoPrevencionForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataAccesoPrevencion}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={AccesoPrevencion}
          setnewdataAccesoPrevencion={setnewdataAccesoPrevencion}
        />
      )
    },
    {
      title: 'Inspección de Alturas', 
      component: (
        <InspeccionAlturaForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataInspeccionAltura}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={InspeccionAltura}
          setnewdataInspeccionAltura={setnewdataInspeccionAltura}
        />
      )
    },
    { 
      title: 'Análisis de Trabajo', 
      component: (
        <AnalisisTrabajoForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataAnalisisTrabajo}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={AnalisisTrabajo}
          setnewdatanewdataAnalisisTrabajo={setnewdatanewdataAnalisisTrabajo}
        />
      )
    },
    { 
      title: 'Aprobador', 
      component: (
        <SupervisorForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataSupervisor}
          onClose={onClose}
          onTableRefresh={handleTableRefresh}
          handlePrev={handlePrev}
          id={SuperCoord}
          setnewdataSupervisor={setnewdataSupervisor}
        />
      )
    },
  ];

  const StepsPTC = [
    { 
      title: 'Datos Actividad', 
      component: (
        <DatosActividadFormUpdate
          FormularioSigUpdate={newdataDatosActividad}
          onRefetch={onRefetch}
          onTableRefresh={handleTableRefresh}
          handleNext={handleNext}
          idFormularioPrincipal={idFormularioPrincipal}
          setnewData={setnewdataDatosActividad}
        />
      )
    },
    {
      title: 'Condiciones de Salud', 
      component: (
        <CondicionSaludForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataCondicionesSalud}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={CondicionesSalud}
          setnewdataCondicionesSalud={setnewdataCondicionesSalud}
        />
      )
    },
    {
      title: 'Control de Riesgos', 
      component: (
        <ControlRiesgosForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataControlRiesgos}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={ControlRiesgos}
          setnewdataControlRiesgos={setnewdataControlRiesgos}
        />
      )
    },
    {
      title: 'Acceso y Prevención', 
      component: (
        <AccesoPrevencionForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataAccesoPrevencion}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={AccesoPrevencion}
          setnewdataAccesoPrevencion={setnewdataAccesoPrevencion}
        />
      )
    },
    { 
      title: 'Análisis de Trabajo', 
      component: (
        <AnalisisTrabajoForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataAnalisisTrabajo}
          handleNext={handleNext}
          handlePrev={handlePrev}
          id={AnalisisTrabajo}
          setnewdatanewdataAnalisisTrabajo={setnewdatanewdataAnalisisTrabajo}
        />
      )
    },
    { 
      title: 'Aprobador', 
      component: (
        <SupervisorForm
          dataTipoFormulario={tipoFormularioPrincipal}
          data={newdataSupervisor}
          onClose={onClose}
          onTableRefresh={handleTableRefresh}
          handlePrev={handlePrev}
          id={SuperCoord}
          setnewdataSupervisor={setnewdataSupervisor}
        />
      )
    },
  ];

  const renderSteps = () => {
    if (!showSteps) {
      return null;
    }

    return (
      <div>
        {tipoFormularioPrincipal === "ATS" && (
          <>
            <Stepper activeStep={activeStep} alternativeLabel nonLinear>
              {StepsATS.map((step, index) => (
                <Step key={index}>
                  <StepLabel>{step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <br/>
            <hr/>
            <div style={{ marginTop: '35px' }} className="steps-content">
              {StepsATS[activeStep].component}
            </div>
          </>
        )}
        {tipoFormularioPrincipal === "PT Alturas" && (
          <>
            <Stepper activeStep={activeStep} alternativeLabel nonLinear>
              {StepsPTA.map((step, index) => (
                <Step key={index}>
                  <StepLabel>{step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <br/>
            <hr/>
            <div style={{ marginTop: '35px' }} className="steps-content">
              {StepsPTA[activeStep].component}
            </div>
          </>
        )}
        {tipoFormularioPrincipal === "PT Confinado" && (
          <>
            <Stepper activeStep={activeStep} alternativeLabel nonLinear>
              {StepsPTC.map((step, index) => (
                <Step key={index}>
                  <StepLabel>{step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <br/>
            <hr/>
            <div style={{ marginTop: '35px' }} className="steps-content">
              {StepsPTC[activeStep].component}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      {!dataLoaded &&       
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </div>
      }
      {renderSteps()}
    </div>
  );
}

export function ChangeStatusForm(props) {
  const { FormularioSig, onClose, onRefetch } = props;
  const { updateStatusSig } = useFormularioSig();
  const { auth } = useAuth();
  const formID = FormularioSig.Id_formulario;
  const formStatus = FormularioSig.estado;
  const field = 'estado';
  const myId = auth.me?.id_user

  const [loading, setLoading] = useState({
    aprobar: false,
    rechazar: false,
    anular: false
  });

  const formik = useFormik({
    initialValues: {
      estado: formStatus // Estado actual del formulario
    },
    onSubmit: async (values) => {
      try {
        let nuevoEstado;
        let mensaje = "";

        if (values.estado === 2) {
          nuevoEstado = 2;
          mensaje = `El formulario con ID ${formID} ha sido aprobado`;
          setLoading({ ...loading, aprobar: true });
        } else if (values.estado === 3) {
          nuevoEstado = 3;
          mensaje = `El formulario con ID ${formID} ha sido rechazado`;
          setLoading({ ...loading, rechazar: true });
        } else if (values.estado === 4) {
          nuevoEstado = 4;
          mensaje = `El formulario con ID ${formID} ha sido anulado`;
          setLoading({ ...loading, anular: true });
        }

        await updateStatusSig(formID, field, nuevoEstado, myId);

        onRefetch();
        onClose();
        message.success(mensaje);
      } catch (error) {
        message.error('Error al actualizar el estado del formulario');
      } finally {
        setLoading({ aprobar: false, rechazar: false, anular: false });
      }
    }
  });

  return (
    <Form onFinish={formik.handleSubmit}>
      <Space>
        <Button
          type="primary"
          style={{ background: "#60C000" }}
          htmlType="submit"
          onClick={() => formik.setFieldValue('estado', 2)}
          loading={loading.aprobar}
        >
          Aprobar
        </Button>
        <Button
          type="primary"
          style={{ background: "rgb(68, 68, 68)" }}
          htmlType="submit"
          onClick={() => formik.setFieldValue('estado', 3)}
          loading={loading.rechazar}
        >
          Rechazar
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          style={{ background: "rgb(204, 48, 43)" }}
          onClick={() => formik.setFieldValue('estado', 4)}
          loading={loading.anular}
        >
          Anular
        </Button>
      </Space>
    </Form>
  );
}

export function ChangeStatusFormMe(props) {
  const { estadoDifundido, onClose, onRefetch, difundidoFormID, difundidoFormEmail } = props;
  const { updateStatusSigMe } = useFormularioSig();
  const field = 'estado';

  const [loading, setLoading] = useState({
    aprobar: false,
    rechazar: false,
  });

  const formik = useFormik({
    initialValues: {
      estado: estadoDifundido // Estado actual del formulario
    },
    onSubmit: async (values) => {
      try {
        let nuevoEstado;
        let mensaje = "";

        if (values.estado === "Aprobado") {
          nuevoEstado = "Aprobado";
          mensaje = `La aprobación del formulario difundido con ID ${difundidoFormID} ha sido aprobada`;
          setLoading({ ...loading, aprobar: true });
        } else if (values.estado === "Rechazado") {
          nuevoEstado = "Rechazado";
          mensaje = `La aprobación del formulario difundido con ID ${difundidoFormID} ha siso rechazada`;
          setLoading({ ...loading, rechazar: true });
        }

        await updateStatusSigMe(difundidoFormID, nuevoEstado, difundidoFormEmail);

        onRefetch();
        onClose();
        message.success(mensaje);
      } catch (error) {
        message.error('Error al actualizar el estado del formulario');
      } finally {
        setLoading({ aprobar: false, rechazar: false});
      }
    }
  });

  return (
    <Form onFinish={formik.handleSubmit}>
      <Space>
        <Button
          type="primary"
          style={{ background: "#60C000" }}
          htmlType="submit"
          onClick={() => formik.setFieldValue('estado', "Aprobado")}
          loading={loading.aprobar}
        >
          Aprobar
        </Button>
        <Button
          type="primary"
          style={{ background: "rgb(204, 48, 43)" }}
          htmlType="submit"
          onClick={() => formik.setFieldValue('estado', "Rechazado")}
          loading={loading.rechazar}
        >
          Rechazar
        </Button>
      </Space>
    </Form>
  );
}

export function FormularioSigFullView(props){
  //Obtención de los props desde el componente table de formularios Sig
  const { FormularioSigView } = props;
  
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //Extracción del tipo de formulario y el ID del formulario relacionado (principal-adyancente)
  const tipoFormularioPrincipal = FormularioSigView.tipoformulario ? FormularioSigView.tipoformulario : FormularioSigView.ftipoformulario[0];
  const idFormularioPrincipal = FormularioSigView.Id_formulario;
  const [showData, setRenderData] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  //Extracción de todos los datos del Id_formulario relacionado (Hooks)
  const { getAccesoPrevencionID, AccesoPrevencion } = useAccesoPrevencion();
  const { getAnalisisRiesgosID, AnalisisTrabajo } = useAnalisisTrabajo();
  const { getCondicionesSaludID, CondicionesSalud } = useCondicionesSalud();
  const { getControlRiesgosID, ControlRiesgos} = useControlRiesgos();
  const { getInspeccionAlturasID, InspeccionAltura } = useInspeccionAltura();
  const { getQuienDifundeID, QuienDifunde } = useQuienDifunde();
  const { getQuienElaboraID, QuienElabora } = useQuienElabora();
  const { getRiesgoPotencialID, RiesgoPotencial } = useRiesgoPotencial();
  const { getSuperCoordID, SuperCoord } = useSuperCoord();
  const { getVerificacionDocsID, VerificacionDoc } = useVerificacionDocs();

  /* Hook para traer los datos especificos de los formulario adyacentes*/ 
  useEffect(() => {
    // Función para cargar todos los datos necesarios
    const fetchData = async () => {
      try {

        if(tipoFormularioPrincipal === "ATS"){
          await Promise.all([
            getRiesgoPotencialID(idFormularioPrincipal),
            getControlRiesgosID(idFormularioPrincipal),
            getVerificacionDocsID(idFormularioPrincipal),
            getQuienElaboraID(idFormularioPrincipal),
            getQuienDifundeID(idFormularioPrincipal),
            getAnalisisRiesgosID(idFormularioPrincipal),
            getSuperCoordID(idFormularioPrincipal),
          ]);
        }

        if (tipoFormularioPrincipal === "PT Alturas"){
          await Promise.all([
            getControlRiesgosID(idFormularioPrincipal),
            getCondicionesSaludID(idFormularioPrincipal),
            getAccesoPrevencionID(idFormularioPrincipal),
            getInspeccionAlturasID(idFormularioPrincipal),
            getAnalisisRiesgosID(idFormularioPrincipal),
            getSuperCoordID(idFormularioPrincipal),
          ]);
        }

        if (tipoFormularioPrincipal === "PT Confinado"){
          await Promise.all([
            getCondicionesSaludID(idFormularioPrincipal),
            getControlRiesgosID(idFormularioPrincipal),
            getAccesoPrevencionID(idFormularioPrincipal),
            getAnalisisRiesgosID(idFormularioPrincipal),
            getSuperCoordID(idFormularioPrincipal),
          ]);
        }
        setDataLoaded(true); // Marca como cargados todos los datos
      } catch (error) {
        message.error('Error al cargar datos');
      }
    };

    fetchData();
  }, [idFormularioPrincipal, tipoFormularioPrincipal]);

  useEffect(() => {
    // Mostrar los datos una vez que los datos estén cargados
    if (dataLoaded) {
      setRenderData(true);
    }
  }, [dataLoaded]);

  const renderData = () => {
    if (!showData) {
      return null; // No renderizar los steps si showSteps es false
    }

    return (
      <div>
        <Row justify="space-between" align="middle" style={{ background: 'gray', borderRadius: '0px 100px 0px 0px' }}>
            <Col span="8">
                <img src={logosyz} alt='noimage' width="150px" height="80px" style={{ padding: '10px' }} />
            </Col>
            <Col flex="1" style={{ textAlign: 'center' }}>
                <Row style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    FORMATO ANALISIS DE TRABAJO SEGURO
                </Row>
            </Col>
        </Row>
        
        {tipoFormularioPrincipal === "ATS" && (
        <>
          <DatosActividadFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={FormularioSigView}
          />
          <hr/>
          <RiesgoPotencialFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={RiesgoPotencial}
          />
          <hr/>
          <ControlRiesgosFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={ControlRiesgos}
          />
          <hr/>
          <AnalisisTrabajoFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={AnalisisTrabajo}
          />
          <hr/>
          <VerificacionDocFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={VerificacionDoc}
          />
          <hr/>
          <QuienElaboraFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={QuienElabora}
          />
          <hr/>
          <QuienDifundeFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={QuienDifunde}
          />
          <hr/>
          <SupervisorFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={SuperCoord}
          />
          <hr/>
        </> 
        )}

        {tipoFormularioPrincipal === "PT Alturas" && (
        <>
          <DatosActividadFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={FormularioSigView}
          />
          <hr/>
          <ControlRiesgosFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={ControlRiesgos}
          />
          <hr/>
          <AnalisisTrabajoFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={AnalisisTrabajo}
          />
          <hr/>
          <CondicionSaludFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={CondicionesSalud}
          />
          <hr/>
          <AccesoPrevencionFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={AccesoPrevencion}
          />
          <hr/>
          <InspeccionAlturaFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={InspeccionAltura}
          />
          <hr/>
          <SupervisorFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={SuperCoord}
          />
        </>
        )}

        {tipoFormularioPrincipal === "PT Confinado" && (
        <>
          <DatosActividadFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={FormularioSigView}
          />
          <hr/>
          <ControlRiesgosFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={ControlRiesgos}
          />
          <hr/>
          <AnalisisTrabajoFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={AnalisisTrabajo}
          />
          <hr/>
          <CondicionSaludFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={CondicionesSalud}
          />
          <hr/>
          <AccesoPrevencionFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={AccesoPrevencion}
          />
          <hr/>
          <SupervisorFullView
              dataTipoFormulario={tipoFormularioPrincipal}
              data={SuperCoord}
          />
        </>
        )}

        

      </div>
    );
  }

  return (
    <div>
      {!dataLoaded &&       
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
        </div>
      }

        <div ref={componentRef}>
          {renderData()}
        </div>

        <Button type="primary" onClick={handlePrint}>Generar PDF</Button>

    </div>
  );
}
