import React, { useEffect } from "react";
import * as Yup from "yup";
import { Button, Col, Form, Row, message, Select, Input, Table, Spin } from "antd";
import { useFormik } from "formik";
import { FormStyle } from "../../../comons/styles";
import { useVerificacionDocs } from "../../../../hooks/administrativa/formularios-sig/useVerificacionDoc";
import { initialValuesVerificacionDoc } from "../components/initialValues";
import { CircularProgress } from "@mui/material";

export function VerificacionDocForm(props) {

  const { updateVerificacionDocs, cancelLoader, loading } = useVerificacionDocs();
  const { dataTipoFormulario, data, handleNext, handlePrev, id, setnewdataVerificacionDoc } = props;
  const { Option } = Select;

  useEffect(() => {
    cancelLoader();
  }, [data])

  const formik = useFormik({
    initialValues: initialValuesVerificacionDoc(id.Id_formulario, data),
    //validationSchema: analisisTrabajoSchema,

    onSubmit: async (formValues) => {
      try {
        await updateVerificacionDocs(id.Id_verificaciondoc, formValues);
        message.success("Actualización exitosa (Verificación de documentos)");
        setnewdataVerificacionDoc(formValues)
        handleNext();
      } catch (error) {
        message.error("Error en la operación (Verificación de documentos)");
      }
    },
  });

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Form layout="vertical" onFinish={formik.handleSubmit} style={FormStyle}>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                  label="¿El equipo ejecutor tiene las competencia para desarrollar el trabajo?"
                  validateStatus={formik.errors.equipotienecompetencias && formik.touched.equipotienecompetencias ? "error" : ""}
                  help={formik.touched.equipotienecompetencias && formik.errors.equipotienecompetencias ? formik.errors.equipotienecompetencias : ""}
              >
                <Select
                    name="equipotienecompetencias"
                    value={formik.values.equipotienecompetencias ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('equipotienecompetencias', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  label="¿El equipo ejecutor tiene claro los riesgos a los cuales se encontrara expuesto en la ejecución del trabajo?"
                  validateStatus={formik.errors.tieneclaroriesgos && formik.touched.tieneclaroriesgos ? "error" : ""}
                  help={formik.touched.tieneclaroriesgos && formik.errors.tieneclaroriesgos ? formik.errors.tieneclaroriesgos : ""}
              >
                <Select
                    name="tieneclaroriesgos"
                    value={formik.values.tieneclaroriesgos ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('tieneclaroriesgos', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  label="¿El equipo ejecutor tiene claras las medidas de control y procedimiento seguros que debe seguir para ejecutar el trabajo?"
                  validateStatus={formik.errors.medidasdecontrol && formik.touched.medidasdecontrol ? "error" : ""}
                  help={formik.touched.medidasdecontrol && formik.errors.medidasdecontrol ? formik.errors.medidasdecontrol : ""}
              >
                <Select
                    name="medidasdecontrol"
                    value={formik.values.medidasdecontrol ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('medidasdecontrol', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                  label="¿El equipo ejecutor tiene claras sus funciones y responsabilidad en la ejecución del trabajo?"
                  validateStatus={formik.errors.funcionesyresponsabilidades && formik.touched.funcionesyresponsabilidades ? "error" : ""}
                  help={formik.touched.funcionesyresponsabilidades && formik.errors.funcionesyresponsabilidades ? formik.errors.funcionesyresponsabilidades : ""}
              >
                <Select
                    name="funcionesyresponsabilidades"
                    value={formik.values.funcionesyresponsabilidades ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('funcionesyresponsabilidades', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  label="¿El equipo de trabajo cuenta con todos los equipos de seguridad requeridos para ejecutar el trabajo?"
                  validateStatus={formik.errors.todosequiposseguridad && formik.touched.todosequiposseguridad ? "error" : ""}
                  help={formik.touched.todosequiposseguridad && formik.errors.todosequiposseguridad ? formik.errors.todosequiposseguridad : ""}
              >
                <Select
                    name="todosequiposseguridad"
                    value={formik.values.todosequiposseguridad ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('todosequiposseguridad', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  label="¿El equipo ejecutor cuenta con todos los elementos de protección personal adecuado y en buen estado para ejecutar el trabajo?"
                  validateStatus={formik.errors.eppadecuados && formik.touched.eppadecuados ? "error" : ""}
                  help={formik.touched.eppadecuados && formik.errors.eppadecuados ? formik.errors.eppadecuados : ""}
              >
                <Select
                    name="eppadecuados"
                    value={formik.values.eppadecuados ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('eppadecuados', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                  label="¿El equipo de trabajo cuenta con los equipos y herramientas adecuadas para ejecutar el trabajo?"
                  validateStatus={formik.errors.equpoyherramienta && formik.touched.equpoyherramienta ? "error" : ""}
                  help={formik.touched.equpoyherramienta && formik.errors.equpoyherramienta ? formik.errors.equpoyherramienta : ""}
              >
                <Select
                    name="equpoyherramienta"
                    value={formik.values.equpoyherramienta ? "true" : "false"}
                    onChange={(value) => {
                        const booleanValue = value === "true";
                        formik.setFieldValue('equpoyherramienta', booleanValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                >
                    <Option value="true">Si</Option>
                    <Option value="false">No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button type="primary" htmlType="button" onClick={handlePrev}>
              Anterior
            </Button>
            <Button type="primary" htmlType="submit">
              Actualizar
            </Button>
            <Button type="primary" style={{ background: "black", marginLeft: "5px" }} onClick={handleNext}>
              Siguiente
            </Button>
          </div>
        </Form>
      )}
    </>
  )
}

export function VerificacionDocFullView(props) {
  const { data } = props;
  const { cancelLoader, loading } = useVerificacionDocs();

  useEffect(() => {
    cancelLoader();
  }, [data]);

  const formik = useFormik({
    initialValues: initialValuesVerificacionDoc(data.Id_formulario, data),
  });

  const columns = [
    {
      title: 'Pregunta',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Respuesta',
      dataIndex: 'answer',
      key: 'answer',
    },
  ];

  const dataSource = [
    { question: '¿El equipo ejecutor tiene las competencias para desarrollar el trabajo?', answer: formik.values.equipotienecompetencias ? 'Sí' : 'No' },
    { question: '¿El equipo ejecutor tiene claro los riesgos a los cuales se encontrará expuesto en la ejecución del trabajo?', answer: formik.values.tieneclaroriesgos ? 'Sí' : 'No' },
    { question: '¿El equipo ejecutor tiene claras las medidas de control y procedimiento seguros que debe seguir para ejecutar el trabajo?', answer: formik.values.medidasdecontrol ? 'Sí' : 'No' },
    { question: '¿El equipo ejecutor tiene claras sus funciones y responsabilidades en la ejecución del trabajo?', answer: formik.values.funcionesyresponsabilidades ? 'Sí' : 'No' },
    { question: '¿El equipo de trabajo cuenta con todos los equipos de seguridad requeridos para ejecutar el trabajo?', answer: formik.values.todosequiposseguridad ? 'Sí' : 'No' },
    { question: '¿El equipo ejecutor cuenta con todos los elementos de protección personal adecuados y en buen estado para ejecutar el trabajo?', answer: formik.values.eppadecuados ? 'Sí' : 'No' },
    { question: '¿El equipo de trabajo cuenta con los equipos y herramientas adecuadas para ejecutar el trabajo?', answer: formik.values.equpoyherramienta ? 'Sí' : 'No' },
  ];

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row style={{ fontSize: '25px' }}>Verificación de documentos</Row>
      <hr />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
      />
    </>
  );
}

export default VerificacionDocFullView;

// export function VerificacionDocFullView(props) {
//   const { cancelLoader, loading } = useVerificacionDocs();
//   const { data } = props;

//   useEffect(() => {
//     cancelLoader();
//   }, [data])

//   const formik = useFormik({
//     initialValues: initialValuesVerificacionDoc(data.Id_formulario, data),
//     //validationSchema: analisisTrabajoSchema,
//   });

//   return (
//     <>
//       {loading ? (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "50vh",
//           }}
//         >
//           <CircularProgress />
//         </div>
//       ) : (
//         <Form layout="vertical" style={formStyle}>
//           <Row style={{ fontSize: '25px' }} >Verificación de documentos</Row>
//           <hr/>
//           <Row gutter={16}>
//             <Col span={6}>
//               <Form.Item label="¿El equipo ejecutor tiene las competencia para desarrollar el trabajo?" >
//                 <Input
//                     name="equipotienecompetencias"
//                     value={formik.values.equipotienecompetencias ? 'Si' : 'No'}
//                     readOnly
//                 />   
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label="¿El equipo ejecutor tiene claro los riesgos a los cuales se encontrara expuesto en la ejecución del trabajo?" >
//                 <Input
//                     name="tieneclaroriesgos"
//                     value={formik.values.tieneclaroriesgos ? 'Si' : 'No'}
//                     readOnly
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label="¿El equipo ejecutor tiene claras las medidas de control y procedimiento seguros que debe seguir para ejecutar el trabajo?" >
//                 <Input
//                     name="medidasdecontrol"
//                     value={formik.values.medidasdecontrol ? 'Si' : 'No'}
//                     readOnly
//                 />
//               </Form.Item>
//             </Col>
//           </Row>

//           <Row gutter={16}>
//             <Col span={6}>
//               <Form.Item label="¿El equipo ejecutor tiene claras sus funciones y responsabilidad en la ejecución del trabajo?" >
//                 <Input
//                     name="funcionesyresponsabilidades"
//                     value={formik.values.funcionesyresponsabilidades ? 'Si' : 'No'}
//                     readOnly
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label="¿El equipo de trabajo cuenta con todos los equipos de seguridad requeridos para ejecutar el trabajo?" >
//                 <Input
//                     name="todosequiposseguridad"
//                     value={formik.values.todosequiposseguridad ? 'Si' : 'No'}
//                     readOnly
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label="¿El equipo ejecutor cuenta con todos los elementos de protección personal adecuado y en buen estado para ejecutar el trabajo?" >
//                 <Input
//                     name="eppadecuados"
//                     value={formik.values.eppadecuados ? 'Si' : 'No'}
//                     readOnly
//                 />
//               </Form.Item>
//             </Col>
//           </Row>

//           <Row gutter={16}>
//             <Col span={8}>
//               <Form.Item label="¿El equipo de trabajo cuenta con los equipos y herramientas adecuadas para ejecutar el trabajo?" >
//                 <Input
//                     name="equpoyherramienta"
//                     value={formik.values.equpoyherramienta ? 'Si' : 'No'}
//                     readOnly
//                 />
//               </Form.Item>
//             </Col>
//           </Row>
//         </Form>
//       )}
//     </>
//   )
// }