import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button } from '@mui/material';
import { UseComplaints } from '../../hooks/complaints/UseComplaints';

export function FormRespond({ denuncias, onClose, onRefetch }) {
  const [respuesta, setRespuesta] = useState("");
  const { updateRespuestaDenuncia } = UseComplaints();

  useEffect(() => {
    if (denuncias?.respuesta !== respuesta) {
      setRespuesta(denuncias?.respuesta || "");
    }
  }, [denuncias]);

  const handleSubmit = useCallback(async () => {
    if (!respuesta.trim()) {
      console.error('La respuesta no puede estar vacía.');
      return; // Evita el envío si la respuesta está vacía
    }
  
    try {
      // Llamamos a la función para actualizar la respuesta de la denuncia
      await updateRespuestaDenuncia(denuncias?.id_denuncia, respuesta);
      // Si la respuesta se envía correctamente, refetch los datos y cierra el formulario
      onRefetch();
      onClose();
    } catch (error) {
      console.error('Error al enviar la respuesta:', error);
    }
  }, [denuncias?.id_denuncia, respuesta, updateRespuestaDenuncia, onRefetch, onClose]);
  

  return (
    <div>
      <h2>Responder a la queja</h2>
      <p>Numero de queja: {denuncias?.id_denuncia}</p>
      <p>{denuncias?.nombre_denuncia}</p>
      <TextField
        fullWidth
        multiline
        rows={4}
        label="Escribe tu respuesta"
        value={respuesta}
        onChange={(e) => setRespuesta(e.target.value)}
        variant="outlined"
        margin="normal"
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginRight: 8, backgroundColor: "#444444" }}
        >
          Enviar Respuesta
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          style={{ marginRight: 8, backgroundColor: "#CC302B" }}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
}
